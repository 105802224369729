.form--inline{
	.form-group{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 20px;
		padding-bottom: 20px;

		&:not(:last-of-type){
			border-bottom: 2px solid #ECEFEF;
		}
		.vue-input{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
		}
		h3,
		label,
		legend{
			flex-basis: 16.8%;
			margin-bottom: 0;
			font-size: 1.2rem;
			@include font-medium;
			color:#51534A;
			text-transform: uppercase;
			margin-top: 10px;

		}
		&.submit{
			padding-left:16.8%;
			display: flex;
			justify-content: space-between;
			align-items:center;
		}
	}
	.form-group,
	.vue-input{
		&.remove{
			height: 0 !important;
			overflow: hidden;
			transition: height 0.5s ease-out;
		}
		button.btn-link{
			font-size:1.2rem;
			margin-bottom: 20px;
			.svg-inline--fa{
				width: 2.8rem;
				height: 2.8rem;
			}
		}
		& > .input-group{

			display: flex;
			flex-basis: 50%;
			justify-content: space-between;
			align-items: center;
			flex-wrap:wrap;

			.form-control{
				max-width: 550px;
			}
		}
		.help-text{
			display: flex;
			padding-left:2rem;
			flex-basis: 30%;
			p{
				font-size: 1.2rem;
				font-style:italic;
				@include font-medium;
				margin-bottom: 0;
				text-align: left;
			}
		}
		.errors{
			margin-top: 15px;
			flex-basis:100%;
		}
		&.form__image-info{
			padding-top:0;
			padding-bottom:0;

			.photo-details{

				padding: 20px 0;
				justify-content: flex-start;
				align-items:center;
				flex-basis: 66.66%;


				.photo-title,
				.photo-caption{
					display:flex;
					
					h3{
						display: flex;
						flex-basis: 25%;
						flex-shrink:0;
					}
					.input-group{
						display: flex;
						width: 100%;
						flex-basis: 100%;

					}
				}

			}
			.photo-sample{
				display:flex;
				flex-basis: 33.33%;
				padding-left: 20px;
				padding-top:25px;
			}
		}
	}

	.file-upload-container{
		.upload-area{
			width: 100%;
			height: $input-height;
			background: $input-color;
			padding-left:15px;
			padding-right:15px;
			display: flex;
			flex-wrap: wrap;
			align-items:center;
			padding-top:10px;
			p{
				color:$label-color;
				padding:0;
			}

		}

	}

}

.light-tint-bg{

	.form-control{
		background:#fff;
	}
	.custom-checkbox,
	.custom-radio{
		width: 50%;
		float: left;
		flex-basis:50%;
		display: flex;
		padding-top:0;

		[type="checkbox"]:checked + label,
		[type="checkbox"]:not(:checked) + label,
		[type="checkbox"]:checked + label,
		[type="checkbox"]:not(:checked) + label{
			flex-basis:100%;
			@include font-medium;
			&::before{
				background: #fff;
			}
			&::after{
				color:$link-highlight;
			}
		}
	}

	fieldset.form-group{
		& + .form-group{
			border-top: 2px solid #ecefef;
		}
	}

}	


.inline-checks,
.inline-radios{

	overflow: hidden;
	h3{
		float: left;
		color: #fff;
		margin-right: 30px;
		@include heading-bold();

	}
	.checkbox,
	.radio,
	.custom-checkbox,
	.custom-radio{
		float: left;

		& + .checkbox,
		& + .radio,
		& +.custom-checkbox,
		& +	.custom-radio{
			margin-left: 30px;

		}
	}
}

@include media-breakpoint-down(md){

	.form--inline{
		.form-group,
		.vue-input{
			h3,
			label,
			legend{
				flex-basis: 100%;
				margin-bottom: 10px;
			}
			&.submit{
				padding-left:0;
			}
			& > .input-group{

				flex-basis: 100%;
				.form-control{
					margin-bottom: 10px;
				}

			}
			.help-text{
				padding-left: 0;
				flex-basis: 100%;
			}

			&.form__image-info{


			.photo-details{

				padding: 20px 0;
				flex-basis: 100%;


				.photo-title,
				.photo-caption{
					display:flex;
					flex-wrap: wrap;
					h3{
						display: flex;
						flex-basis: 100px;
						flex-shrink:0;
					}
					.input-group{
						display: flex;
						width: 100%;
						flex-basis: 100%;

					}
				}

			}
			.photo-sample{
				display:flex;
				flex-basis: 50%;
				padding-left: 0;
				padding-top:25px;
			}
		}
		}

	}


}
