
.cpd-header{

	padding-top: 50px;
	padding-bottom: 50px;  
		.subheading{
			color: $brand-color;
			display: block;
			margin-bottom: 15px;
		   @include font-light;
		    text-transform: uppercase;
		    font-size: 1.2rem;
		    color: $brand-colorlightergrey;
		    strong{
		    	@include font-bold;
		    }
		}

}

.cpd-heading{
	h3{

		@include font-light;
		color: $brand-color;
		margin-bottom: 10px;
	}
	.cpd-time__required{
		color: $brand-color;
	
		margin-bottom: 0;
	}
	.cpd-past__points{
		text-transform: uppercase;
		@include heading-medium;
		
		span{
			font-size: 1.2rem;
		}
	}
						
}

.cpd-time__required{
	font-size: 1.6rem;
	@include heading-medium;
	margin-bottom: 5px;
	color: $brand-colorgrey;
}

.cpd-past__points{
	@include heading-regular;
    color: rgba(96, 96, 96, 0.6);
    margin-bottom: 15px;
    display: block;
   	span{
   		font-size: 1.4rem;
   	}
}

.category-1{
	color:#F18F14;
}
.category-2{
	color:#9D228A;
}
.category-3{
	color: #8EC742;
}

.cpd-shape{
	background-size:cover;
	width: 24px;
	height: 22px;
	display: inline-block;
	@include heading-bold;
	font-size: 1.4rem;
	display: inline-flex;
	justify-content: center;
	align-items:center;
	&.category-1{
		background-image: url('../images/system/icons/cpd-category-1-shape.svg');
	}
	&.category-2{
		background-image: url('../images/system/icons/cpd-category-2-shape.svg');
	}
	&.category-3{
		background-image: url('../images/system/icons/cpd-category-3-shape.svg');
	}

}


/* nav */.year-nav{

	display: flex;
	justify-content: space-between;
	align-items: center;

	a,
	.dropdown-toggle{
		font-size:1.6rem;
		padding:6px 18px 4px;
		display: inline-block;
		color:#bdced4;
		background:transparent;
		border:0;
		@include heading-bold;
		transition:0.2s ease-out color;
		line-height: 2rem;

		&:hover,
		&:focus,
		&:focus{
			color:#5E8D9A;
			box-shadow: none;
		}
		&.active{
			background: #DBEDD4;
			color:#5E8D9A;
			border-radius: 15px;
		}
		.svg-inline--fa{
			width: 8px;
			height: 15px;
		}
	}

}

.page-tools{
	display: flex;
	justify-content: space-between;
	.btn-link{
		display: inline-flex;
		color:#acacac;
		align-items:center;
		padding:0;
		.svg-inline--fa{
			width: 18px;
			height: 24px;
			margin-right: 8px;
		}
	}
}

.cpd-total{
	@include heading-bold;

	&__number{
		display: flex;
	    width: 54px;
	    height: 52px;
	    font-size: 2.6rem;
	    margin-bottom: 5px;
	}


	&__category{
	    display: block;
	    font-size: 1rem;
	    text-transform: uppercase;
	}


	&__caption{
		text-align: center;
		display: block;
		font-size: 1.2rem;
		color: #C5D1D7;
	}
 
}


.cpd-totals{
	@include heading-bold;

	&__number{
		font-size:5.1rem;
		line-height:0.7em;
		color:#5E8D9A;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		display:block;


	}
	&__caption{
		display: block;
		font-size: 1.2rem;
		color: #C5D1D7;
		text-transform:uppercase;
	}
 
}

.cpd-target{
	@include heading-bold;

	&__number{
		display: block;
		color:#606060;
		font-size: 2.6rem;
		margin-bottom: 5px;
		.svg-inline--fa{
			width: 17px;
			height: 20px;
			&.fa-tachometer-fastest{
				path{
					fill:#6EAF5F;
				}
			}
		}
		small{    
			display: block;
			font-size:1rem;
			line-height:1em;
		}
	}
	&__caption{
		display: block;
		font-size: 1rem;
		color: #C5D1D7;
		text-transform:uppercase;
	}

}


.cpd-info-group{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 3.5rem;
	h5{
		font-size: 1.2rem;
		letter-spacing: 1.5px;
		color:#aeaeae;
		grid-column-start: 1;
		grid-column-end: 4
	}
	.cpd-target{

	}
}

.cpd-breakdown{
	padding-top:50px;
	padding-bottom:50px;

	.cpd-total,
	.cpd-info-group{
		float: left;
	}
	.cpd-info-group{
		float: right;
	}


	#CpdHistory{
		width: 130px;
		height: 130px;
		float: left;
		.cdc{
			width: 100% !important;
			padding-bottom: 100% !important;
			&-text{
				display: none;
			}
		}

	}
}

