
@keyframes fadeIn{
	0%{
		opacity: 0;
		visibility:hidden;
		display: none;
	}
	1%{
		visibility:visible;
		display: block;
	}
	100%{
		opacity: 1;
	}
}

@keyframes slide-top-fade-in {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-bottom-fade-in {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeOut{
	0%{
		opacity: 1;
		visibility:visible;
		display: block;
		
	}
	99%{
		visibility:hidden;
		display: none;
	}
	100%{
		opacity: 0;
	}
}