
.hero-banner.hero-banner__overlay{
	&.hero-dashboard{
		// min-height: 100%;
		padding-top: 190px !important;
		.breadcrumb{
			margin-bottom: 0;
			li{
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

.dashboard{
	background-color: $brand-color;

	.hero-banner.hero-banner__overlay{
		.banner-top{
			margin-bottom: 0;
		}

		h2{
			margin: 0 0 10px;
		}

		&+.section{
			padding-top: 30px;
			margin-top: 0;
		}
	}

}

.dashboard__links {
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
	margin-top: 25px;

    li{
    	text-align: center;
    	list-style: none;
		text-transform: uppercase;
		max-width: 90px;
    	a{
    		color: rgba(255, 255, 255, 0.5);
    		&:hover{
    			color: $brand-secondary;
    		}
    		svg{
    			font-size: 3.2rem;
    		}
    		span{
    			margin-top: 10px;
    			font-size: 1.2rem;
    			display: block;
    			@include font-bold;
    		}
    	}
    }
}

.dashboard-tile__container{
	-webkit-column-gap: 15px;
    -moz-column-gap: 0;
    column-gap: 15px;
    -webkit-column-width: 30%;
    -moz-column-width: 30%;
    column-width: 30%;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 2;

    .dashboard__bottom{
    	a.more{
			@include font-bold;
			font-size: 1.6rem;
    	}
    }

	.dashboard-tile{
		width: 100%;
		background-color: #fff;
	    -webkit-column-break-inside: avoid;
	    -moz-column-break-inside: avoid;
	    break-inside: avoid;
	    border-radius: 6px;
	    display: inline-block;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    justify-content: center;
	    margin-bottom: 15px;
	    padding-bottom: 15px;

	    .dashboard--zerostate{
	    
		    .zerostate__tile{
			    display: grid;
			    justify-content: center;
			    align-items: center; 
			    text-align: center;


			    .zerostate__icon{
					font-size: 3.2rem;
					color: #a7b2bd;
				}
				.zerostate__tite{
					color: #a7b2bd;
				    font-size: 1.4rem;
				    display: block;
				    @include heading-bold;
					max-width: 150px;
				    margin-bottom: 15px;
				}
				.zerostate__extra{
					@include heading-medium;
					color: $error-color;
				    font-size: 1.2rem;
				}

				&.full{
					padding:40px 0;

				}
			}  
	    }

	    .dashboard__title{
	    	padding: 20px;

	    	&.collapsed{
	    		h6{
					svg{
						transform:rotate(0deg);
					}
	    		}
	    	}
	    	
	    	h6{
	    		margin-bottom: 10px;
	    		text-transform: uppercase;
	    		display: flex;
	    		justify-content: space-between;
	    		align-items: center;
	    		font-size: 1.2rem;
	    		@include font-semibold;

	    		&:after{
					content: "\f078";
					@include fontawesome-regular;
					display: none;
				}
				svg{
					display: none;
				    font-size: 2rem;
				    transform: rotate(180deg);
				    float: right;
				}

	    		
	    	}
	    	
	    }

	    .dashboard__content {
		    border-bottom: 2px rgba(224, 227, 232, 0.5) solid;
		    border-top: 2px rgba(224, 227, 232, 0.5) solid;
		    margin-bottom: 20px;
		    padding-bottom: 40px;
		    padding-top: 40px;
			position: relative;

			&:nth-last-child(1){
			border-bottom:0;	
			    padding-bottom: 0;
			}
		}

		.dashboard__bottom, .dashboard__content{
			margin-left: 20px;
    		margin-right: 20px;
		}
		&__mycpd{
			.dashboard__content{
				display: flex;
			    align-items: center;
			    justify-content: center;
			        padding-bottom: 70px;
			    .cpd__tile{
			    	margin: 0 10px;
			    	text-align: center;
			    	span{
			    		font-size: 1.2rem;
			    		@include font-medium;
			    	}
			    }
			    .cpd__total{
			    	background-color: $brand-color;
			    	overflow: hidden;
			    	position: relative;
			    	text-align: center;
			    	height: 80px;
			    	width: 80px;
			    	border-radius: 100%;
			    	&.complete{
			    		background-color: $brand-quinary;
			    		span{
			    			color: #ffffff;

			    			&:nth-last-child(1){
			    				background-color: #a2c03a;
			    			}
			    		}
			    	}

			    	span{
			    		color: #41516d;
			    		display: block;
			    		font-size: 2.8rem;
			    		@include heading-bold;
			    		&:nth-last-child(1){
			    			border-top: 1px #ffffff solid;;
			    			font-size: 1.8rem;
			    			position: absolute;
						    bottom: 0;
						    padding: 5px;
						    width: 100%;
						    background-color: $brand-color;
			    		}
			    	}
		    	.cpd__extra{
					@include heading-medium;
					color: $lighter-grey;
				    font-size: 1.2rem;
				    margin-top: 15px;
				}
			    }

			   	.cpd__submit {
				    position: absolute;
				    bottom: 10px;
				    left: 0;
				    font-family: omnes-pro, sans-serif;
				    font-weight: 600;
				    font-style: normal;
				    color: #a7b2bd;
				    font-size: 1.2rem;
				}
			}
		}

	    &__myfeed{

	    	.dashboard__content {
			    padding-bottom: 0;
			    padding-top: 0;
			}
	    	ul{
				margin-left: -20px;

				&.notification__panel--zerostate{
					margin-bottom: 30px;
					border-bottom: 2px rgba(224, 227, 232, 0.5) solid;
				}

	    		li{
	    			padding: 15px 10px;
				    border-bottom: 2px rgba(224, 227, 232, 0.5) solid;
				    list-style: none;
				    display: grid;
				    grid-template-columns: 20px 1fr 100px;
				    grid-column-gap: 15px;


				   	align-items: baseline;

					&:nth-last-child(1){
	    				border-bottom:0;
	    			}
				    .notification__icon{
				    	color: #232B35;
				    	font-size: 1.2rem;
				    }
				    .notification__title{
				    	@include heading-bold;
				    	
				    	a{
				    		color: $brand-secondary;
				    		&:hover{
				    			color: $brand-quarterny;
				    		}
				    	}
				    }
				    .notification__date{
				    	@include heading-bold;
				    	color: $brand-dark;
				    	font-size: 1.2rem;
				    	text-transform: uppercase;
				    }

	    		}

	    	}
	    }

	    &__mydetails{
	    	.dashboard__content{
			    display: grid;
			    grid-template-columns: 1fr 1fr 1fr;
			    grid-column-gap: 30px;
			    text-align: center;


				.details__tile{

					&.expired{
						.details__icon{
							font-size: 3.2rem;
							color: #a7b2bd;
						}
						.details__tite{
							color: #a7b2bd;
						    font-size: 1.4rem;
						    display: block;
						    @include font-bold;
						    margin-bottom: 15px;
						}
						.details__extra{
							@include font-medium;
							color: $error-color;
						    font-size: 1.2rem;
						}
					}
					.details__icon{
						font-size: 3.2rem;
						color: $brand-secondary;
					}
					.details__tite{
						color: $brand-color;
					    font-size: 1.4rem;
					    display: block;
					    @include font-bold;
					    margin-bottom: 15px;
					}
					.details__extra{
						@include font-medium;
						color: #a7b2bd;
					    font-size: 1.2rem;
					}
				}
	    	}
	    }
	    &__myevents{
	    	.item{
	    		width: 100%;
    			height: 100%;
		    	.card--icon{
		    		.card__icon{
		    			background-color: $brand-secondary;
		    			svg{
		    				color: #ffffff;
		    			}
		    		}
		    	}
	    	}

			.carousel-indicators{
				position: absolute;
				top: -20px;
				right: 0;
				text-align: right;
				margin-left: unset;
				left: unset;

				li{
					background-color: transparent;
					width:14px;
					height:14px;
					margin:0 3px;
					border:1px solid $border-color;

					&.active{
						width:14px;
						height:14px;
						margin:0 3px;
						background-color: $brand-secondary;
					}
				}
			}
	    }

	    &__myknowledge{
	    	.item{
	    		width: 100%;
    			height: 100%;
		    	
	    	}

			.carousel-indicators{
				position: absolute;
				top: -20px;
				right: 0;
				text-align: right;
				margin-left: unset;
				left: unset;

				li{
					background-color: transparent;
					width:14px;
					height:14px;
					margin:0 3px;
					border:1px solid $border-color;

					&.active{
						width:14px;
						height:14px;
						margin:0 3px;
						background-color: $brand-secondary;
					}
				}
			}
	    }
	    
	}


	.dashboard-tile__mymentoring{
	    	.card{
	    		 .card__listinfo {
				    margin-left: 0;
				    margin-bottom: 20px;
				    a{
				    	font-size: 1.6rem;
				    	color: #404040;
				    	margin-right: 15px;
				    	svg{
				    		color: $brand-secondary;
			    		    font-size: 2rem;
							margin-right: 5px;
				    	}
				    	&:hover{
				    		color:$brand-quinary;
				    	}
				    }
				}
	    	}

	    	.progress-title{
	    		span{
	    			color: rgba(99,115,129,0.7);
	    			font-size: 12px;
	    			@include font-medium();

	    		}
	    	}

	    	.progress{
				border-radius: 20px;
				margin-bottom:40px;
				margin-right:40px;
				box-shadow: none;
				height: 13px;
				position: relative;

	    		.progress-bar{
	    			background-color: $brand-quinary;
	    		}
	    	}

			.check{
				position: absolute;
				right: 0;
				top: 30px;
				font-size: 30px;
				color: rgba(198, 212, 210, 0.51);

				&.finish{
					color:$brand-quinary;
				}
			}
	    }

	    .person-progress{
	    	position: relative;
	    	.check{
	    		top: 4px;
	    	}

	    	.progress{
	    		background-color: #e5eceb;
	    	}
	    }
}

@include media-breakpoint-up(lg) {

}


@include media-breakpoint-down(md) {

	.dashboard-tile__container {
	    column-count: 1;

	    .dashboard-tile{
	    	padding-bottom: 0;
	    	color: $brand-secondary;
	    	.dashboard__title{
	    		cursor: pointer;
	    		h6{
	    			.fa-chevron-down{
						display: block;
					}
	    
	    		}
	    	}
	    }
	    .dashboard-tile__mycpd {
	    	.dashboard__content{
	    		display: block;
    			text-align: center;

	    		.cpd__total{
	    			margin: 0 auto;
	    		}

	    		.cpd__tile{
	    			    width: 35%;
    				display: inline-block;
    				margin-bottom: 15px;
    				.cpd__type{
    					margin-top: 10px;
    				}
	    		}
	    	}
	    }
	    .person-progress{
	    	.check{
	    		    top: 13px;
    font-size: 20px;
	    	}
	    }

	    .dashboard-tile__container {
	    	.dashboard-tile__mymentoring {
	    		.card {
	    			.card__listinfo {
	    				a{
	    					    display: block;
	    					    margin-bottom: 10px;
	    				}
	    			}
	    		}
	    	}
	    }
	}

}


@include media-breakpoint-up(md) {


	

}

@include media-breakpoint-down(sm) {

	


}