.nfss.public {
    .home-intro.blue-bg {
        background-color: $home-navy;
    }

    .home-tile {
        background-color: $home-purple;
        
        &::before {
            display: none;
        }
    }

    .two-col-section {
        .col-lg-6 {
            background-color: rgba(#5BA099, 0.07);

            &:last-of-type {
                background-color: rgba(#5BA099, 0.14);
            }
        }

        p {
            margin-bottom: 60px;
            font-weight: 300;
        }
    }

    .home-cta {
        p {
           margin-bottom: 35px;
        }
        .btn {
            width: auto;
            clear: both;
            float: left;
        }
    }


    @include media-breakpoint-down(md) {
        .home-intro {
            .col-md-4,
            .col-md-8 > .row:last-of-type {
                background-color: $home-navy;
            }

            .home-intro__bg.sm-screen {
                background-position: center center;
            }
        }
    }
}

.btn .fa-external-link-alt {
    margin-right: 6px;
}