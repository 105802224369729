
.btn{
	border-radius: 0; 
	border:0; 
	padding: 13px 15px 13px;
	border-radius: 22px;
	line-height: 1em;
	transition: background-color 0.2s ease-out, color 0.2s ease-out;
	color: #fff;
	font-size: 1.4rem;
	white-space: normal;
	margin-bottom: 15px;
	min-width: 100px;
	box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
	@include heading-bold;

	margin-right: 10px;
	&:nth-last-child(1){ 
		margin-right: 0;
	}

	&.btn-primary,
	&.btn-default{
		color: #fff;
		background-color: $brand-secondary;
		border-color:$brand-secondary;
		border:0;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-color;
			border-color: $brand-color;
			box-shadow: none;
			color:	#fff;
		}
	}

	&.btn-dark{
		color: #fff;
		background-color: $brand-color;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-secondary;
			border-color: $brand-secondary;
			box-shadow: none;
			color: #fff;
		}
	}

	&.btn-light{
		color: $brand-secondary;
		background-color: #EBF6F4;
		border-color:#EBF6F4;
		border:0;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-secondary;
			border-color: $brand-secondary;
			box-shadow: none;
			color:	#fff;
		}
		i{
			margin-right: 5px;
		}
	}

	&.btn-dark{
		color: #fff;
		background-color: $brand-color;
		display: inline-block;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-secondary;
			border-color: $brand-secondary;
			box-shadow: none;
			color: #fff;
		}
	}


	&.btn-secondary,
	&.btn-outline{
		background: #fff;
		border: 1px solid #C6D4D2;
		color: $brand-secondary;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-color;
			border-color: $brand-color;
			box-shadow: none;
			color: #fff;
		}
	}
	
	&.btn-white{
		background: #fff;
		border: 1px solid #C6D4D2;
		color: $brand-secondary;

		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			background-color: $brand-color;
			border-color: $brand-color;
			box-shadow: none;
			color: #fff;
		}
	}

	&.btn-purple{
		background: $home-purple;
		border: 1px solid $home-purple;
		color: #fff;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover,
		&:not(:disabled):not(.disabled):active:focus {
			background-color: #fff;
			box-shadow: none;
			color: $home-purple;
		}
	}
	
}

@include media-breakpoint-down(sm) {

	.btn{
		width: 100%;
	}
}
