
.contact-details{

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	padding-left: 0;
	grid-gap: 30px;
	list-style: none;
	li{
		svg{
			path{
				fill: #DCE0E0;
			}

		}
		h5{
			text-transform: inherit;
		}
		a{
			color: #333;
			margin-top: 0;
			&:hover{
				color: $link-highlight;
			}
			&.more{
				color: $link-highlight;
				&:hover{
					color: #DCE0E0;
				}
			}
		}
	}
}




body{
	&.contact{
		background-color: $brand-quinary-light;

		.form-group{
			label{
				text-transform: inherit;
			}
		}
	}
}

.socials {
	margin-bottom: 30px;
	margin-top: 30px;
	padding-left: 0;
	list-style: none;
	span {
		display: inline-block;
		a{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;
			border-radius: 100%;
			background-color: $brand-color;
			margin-right: 15px;
			svg{
				color: #fff;
			}
		}
	}
}

@include media-breakpoint-up(md) {

.actions-top{
	display: flex;
	justify-content: space-between;

	.action-buttons{
		order: 2;
	
	button{
		&:nth-child(1){
			margin-right: 10px;
		}
	}

	}
}

}

@include media-breakpoint-down(md) {

	.actions-top{

		.action-buttons{
			margin-top: 30px;
		    padding-bottom: 10px;
		}
	}
}

