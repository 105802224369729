//custom file upload
input[type="file"]{
	display: none !important;

}
.custom-file-upload{
	width: 100%;
	position: relative;

	.browse-inner{
		position: absolute;
		z-index: 6;
		left: 10px;
		top: 38px;

		span{
			color: rgba(64, 64, 65, 0.5);
			@include font-medium;
			font-size: 1.4rem;
			padding-left: 10px;

			&.file{
				color:$label-color;
			}
		}
	}

	svg{
		color: #3FA197;
		font-size: 1.6rem;
		vertical-align: -4px;
	}


	&.no-label{
		.browse-inner{
			top: 17px;
		}
	}

	&:focus{
		box-shadow:none;
		border-color: $label-color;
	}

	&::after {
		content:"";
		border-radius: 3px;
		display: block;
		padding: 10px 10px 10px 35px;
		cursor: pointer;
		width:100%;
		height:$input-height;
		margin-top: 5px;
		border: 1px solid $border-color;
		background: #fff;
	}
}

//custom select
.custom-select {
	position: relative;
}

.custom-select select {
	display: none; /*hide original SELECT element:*/
}

.select-selected {
	height:$input-height;
	border:1px solid $border-color;
	border-radius: 3px;
	font-size: 14px;
	line-height:30px ;
	@include font-medium;
	padding:5px 10px;
	color:$label-color;
	background: #fff;
}

/*style the arrow inside the select element:*/
.select-selected:after {
	position: absolute;
	content: "";
	top: 20px;
	right: 15px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: $label-color transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active{
	background: $brand-secondary;
	color:#fff;

	&:after {
		border-color: transparent transparent $brand-secondary transparent;
		top: 7px;
	}
}
/*style the items (options), including the selected item:*/
.select-items div{
	color:#404040;
	cursor: pointer;
	user-select: none;
	font-size: 14px;
	line-height:30px ;
	@include font-medium;
	padding:5px 10px;
}
/*style items (options):*/
.select-items {
	position: absolute;
	background-color: $light-blue-bg;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	min-height: 190px;
}

/*hide the items when the select box is closed:*/
.select-hide {
	display: none;
}

.select-items div:hover, .same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}


//input number
.quantity{
	position: relative;
	width: 100%;
	max-width: 80px !important;
	height: 40px;
	font-size: 14px;
	font-weight: 700;
	border: 1px solid #ececec;

	input {
		display: block;
		width: 100%;
		padding-left: 8px;
		border: 0 none;
		float: left;
		background: transparent;
		-moz-appearance: textfield;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:focus{
			outline: none;

		}
	}
	&__btn {
		position: absolute;
		right: 0;
		width: 23px;
		height: calc($input-height / 2);
		border-left:1px solid $border-color;
		border-bottom:1px solid $border-color;

		cursor: pointer;
		&:after {
			position: absolute;
			content: "";
			left: 7px;
			border-width: 5px;
			border-style: solid;
		}
		&--up {
			top: 0;
			&:after {
				top: 2px;
				border-color: transparent transparent #404040 transparent;
			}
		}
		&--down {
			bottom: 0;
			&:after {
				bottom: 2px;
				border-color: #404040 transparent transparent transparent;
			}
		}
	}
}
