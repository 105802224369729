.header-wrap{
  padding-top: 15px;
  position: absolute;
  width: 100%;
  z-index: 11;



	.top-nav{
		.navbar-brand>img{
			position: relative;
		    z-index: 1;
		}

		hr.display-sm {
			margin-bottom: 0;
		}

		.navbar-tools{
			text-align: right;

			a:not(.btn){
				color: #a7b2bd;

				&:hover,
				&:focus,
				&:hover:focus,
				&:active,
				&:active:hover,
				&:active:hover:focus,
				&:active:focus{
					color: $brand-quarterny;
				}
			}

			.list-inline{
				& > li{
					margin-left: 25px;
				}

				li{
					

					a:not(.btn){
						@include heading-bold;
					}
				}

				
			}
		}
	}
	

}


@include media-breakpoint-up(xl) {

	.header-wrap{
		.collapse-nav{
			display: grid;
    		align-items: flex-end;
		}

		.top-nav{
			position: absolute;
		}
		.nav-flex{
			display: flex;
			align-items: flex-end;
			border-bottom: 2px solid rgba(223, 227, 232, 0.1);
		}
		.display-sm{
			display: none;
		}
	}


}


@include media-breakpoint-down(xl) {
	.top-nav{
		position: relative;
    	z-index: 30;
	}

}


@include media-breakpoint-up(md) {

	.top-nav {
		//margin-bottom: 1rem;
	}
	
}

@include media-breakpoint-down(lg) {

	.header-wrap {
		width: 100%;
		z-index: 11;
		padding-top: 10px;

		.navbar-logo{
			img{
				width: 160px;
			}
		}

	}

}
