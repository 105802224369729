 //fonts



@mixin fontawesome-light(){
	font-family: "Font Awesome 5 Light";
	font-weight: 300;

}
@mixin fontawesome-solid(){
	font-family: "Font Awesome 5 Solid";
	font-weight: 900;
}
@mixin fontawesome-regular(){
	font-family: "Font Awesome 5 Regular";
	font-weight: 500;
}
@mixin fontawesome-brand(){
	font-family: 'Font Awesome 5 Brands';
	font-weight: normal;
}

@mixin font-light(){
	font-family: 'Campton W05 Light' , Helvetica, Arial, sans-serif;
	font-weight: inherit;
}

@mixin font-regular(){
	font-family: 'Campton W05 Book' , Helvetica, Arial, sans-serif;
	font-weight: inherit;
}

@mixin font-medium(){
	font-family: 'Campton W05 Medium' , Helvetica, Arial, sans-serif;
	font-weight: inherit;
}

@mixin font-semibold(){
	font-family: 'Campton W05 SemiBold' ,  Helvetica, Arial, sans-serif;
	font-weight: inherit;
}


@mixin font-bold(){
	font-family: 'Campton W05 Bold' ,  Helvetica, Arial, sans-serif;
	font-weight: inherit;
}



@mixin sub-heading-light(){
	font-family: Campton , Helvetica, Arial, sans-serif;
	font-weight: 300;

}
@mixin sub-heading-regular(){
	font-family: Campton , Helvetica, Arial, sans-serif;
	font-weight: 400;

}
@mixin sub-heading-medium(){
	font-family: Campton ,  Helvetica, Arial, sans-serif;
	font-weight: 500;

}
@mixin sub-heading-bold(){
	font-family: Campton ,  Helvetica, Arial, sans-serif;
	font-weight: 700;

}



@mixin heading-regular(){
	font-family: omnes-pro, sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin heading-medium(){
	font-family: omnes-pro, sans-serif;
	font-weight: 600;
	font-style: normal;
}

@mixin heading-bold(){
	font-family: omnes-pro, sans-serif;
	font-weight: 700;
	font-style: normal;

}

