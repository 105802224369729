.custom-checkbox, 
.custom-radio{
	margin-bottom: 10px;
	padding-left: 15px;
	padding-bottom:0;
	margin-top: 0;

	&:last-of-type{
		margin-bottom: 0;
	}

	[type="radio"]:checked,
	[type="radio"]:not(:checked),
	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {

		position: absolute;
		opacity: 0;
		& + label{
			position: relative;
			cursor: pointer;
			display: inline-block;
			line-height: 25px;
			@include font-medium();
			padding-left: 35px;

			&:before{
				content: '';
				position: absolute;
				left: 0px;
				top: 0;
				width: 24px;
				height: 24px;
				border:1px solid $border-color;
				background: #fff;
			}
			&:after{
				opacity: 0;
				transition: all 0.2s ease-out;
				position: absolute;
				line-height: 30px;

			}

			.svg-inline--fa{
				opacity: 0;
			}
		}
	}


	[type="radio"]:not(:checked),
	[type="checkbox"]:not(:checked){

		& + label{
			&:after {
				transform: scale(0);
			}
		}
	}

	[type="radio"]:checked,
	[type="radio"]:not(:checked) {
		& + label{
			&:before{
				border-radius: 50%;
				background: #fff;

			}
			&:after{
				content: '';
				width: 16px;
				height: 16px;
				background: $brand-secondary;
				top: 4px;
				left: 4px;
				border-radius: 50%;
				border: none;
			}
		}
	}
	
	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked){
		& + label{
			&:before{
				border-radius: 0px;
				background: #fff;

			}
			&::after{
				content: '\f00c';
				@include fontawesome-solid();
				display: none;
			}

			.svg-inline--fa {
				position:absolute;
				display: block;
				top: 5px;
				left: 5px;
				transform: scale(0);
				transition:all ease-out 0.2s;
				path{
					color: $brand-secondary;
				}
			}

		}
	}

	[type="radio"]:checked,	
	[type="checkbox"]:checked {

		& + label{
			&:after {
				opacity: 1;
				transform: scale(1);
			}
			.svg-inline--fa{
				transform: scale(1);
				opacity: 1;
			}
		}
	}

}



