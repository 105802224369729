

.card.card--member{
   background-color: #FAFBFC;
   padding: 40px;

   .card__subheading{
     span{
        margin-left: 0;
        margin-bottom: 0;
        display: block;
        @include font-semibold;
        font-size: 16px;
            line-height: 30px;
                color: #667380;
     }
   }


   .card__listinfo{
      margin-left: 0;   

      li {
         padding-top: 10px;
         margin-bottom: 10px;
         border-top: 1px #E0E3E8 solid;

        &.card__membership{
          margin-top: 20px;
          border-top: 0; 
         }

         svg{
              font-size: 1.6rem; 
         }

         color: #3FA197;
        font-size: 1.4rem;
        @include font-semibold;
        letter-spacing: 0;
        line-height: 22px;
        a{
         color: #3FA197;
          font-size: 1.4rem;
          @include font-semibold;
          letter-spacing: 0;
          line-height: 22px;
          &:hover{
            color: #667380;
          }
        }
      }
   }

}

@include media-breakpoint-down(md) {

   

}