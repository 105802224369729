.event-detail, .resource-detail,.perople-detail, body.people{
  background-image: url(../images/system/bg-pattern-tile.png);
}


  .hero-banner .breadcrumb{
    background: transparent;
    color:$border-color;
    padding-left: 0;

    li{
      font-size: 12px;
      padding-right:40px;

      &:before{
        display: none;
      }

      &:first-child{
        font-size: 18px;
        padding-right:45px;
      }

      &:last-child{
        padding-right:0;
      }
    }
  }


  .content-container{
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);

    .breadcrumb{
      background: transparent;
      margin-left: -15px;

      li{
        font-size: 12px;
      }
    }
  }

  //suggest articles
  .suggest{
    margin-top: -120px;
  }


 .suggested-container{
      padding-top:50px;
      border-bottom: 1px #E0E3E8 solid;
      margin-bottom: 40px;
      padding-bottom: 10px;

      h4{
        margin-bottom: 0;
      }

      .more{
        margin: 0;
      }


      .breadcrumb.arrow{
        margin-bottom: 0;
        li{
          
          color:$gray-text;
        }
      }
    } 

.calendar-section{
    background: $brand-secondary;
    width:100%;
    border-radius: 5px 5px 0 0;
    display: inline-flex;
    height:200px;
    background-image: url("../images/system/koru-lines-white.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;

    svg{
      color:#fff;
      font-size: 65px;
    }

    span{
      @include font-bold;
      font-size: 14px;
      line-height: 30px;
    }

    .block{
      margin-left:50px;
      padding-top: 60px;


      &:first-child{
        margin-left:75px;
      }

      .title{
        font-size: 20px;
        @include font-light;
        margin-top: 10px;
        color:#fff;
      }

      span{
        color: rgba(198, 212, 210, 0.7);
        font-size: 12px;
        @include font-semibold;
      }

      &:last-child{
        position: absolute;
        right: 120px;
        top:20px;

        .btn{
          padding: 13px 35px 13px;
        }
      }
    }

  }





@include media-breakpoint-down(lg) {
 
.suggested-container{
  border-bottom: 0;
  padding-top: 10px;
    margin-bottom: 0;

    .card{
      border-top: 0;
    }

}

}  

@include media-breakpoint-down(md) {
 
  .suggested-container{
    .breadcrumb{
      li{
        display: block;
        &:nth-child(1){
          margin-bottom: 10px;
        }
        &:before{
          padding: 0;
        }
        svg{
          margin-left: 0;
        }
      }
    }

  }  
.calendar-section {
    padding: 20px;
    display: block;
    height: 100%;
    background-position: 140% 100%;

    .block{
      
      margin-left: 0;
      padding-top: 10px;;
      &:first-child {
          margin-left: 0;
      }
      &:last-child {
          position: relative;
          left: 0;
          top: 0;
          .btn{
            width: 100%;
          }
      }
    }


}

.hero-banner{
   .breadcrumb {
        li {
          display: block;
          margin-left: 0;
      }
   }
}

}

@include media-breakpoint-down(sm) {

}

@include media-breakpoint-down(xs) {
}