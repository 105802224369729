
.hero-banner{
	position:relative;
	background-color: $brand-secondary;
	background-image:url("../images/system/bg-pattern-tile.png");
	min-height: 375px;

	+ .section:not([class*="-bg"]){
		background-color: #ffffff;
	}


	.banner__content{
		padding-top: 50px;
		padding-bottom: 60px;
	}


	.img-container{
		position: absolute;
		top: 0;
		padding:0;
		object-fit: cover;
		z-index: -1;
		max-width: none;
		min-height: 100%;
		min-width: 100%
	}

	h1{
		color:#fff;
		text-align: center;
		margin:0 auto;
		padding-bottom: 15px;
	}

	p{
		color:#efefef;
	}

	.overlay-content{
		margin-bottom: -100px;
	}




	/**
		START Hero banner with form
		*/
		&.hero-banner--form{


			.banner__content{
				padding-bottom: 60px;
			}

			.text-content{
				padding-bottom: 30px;
			}

			+ .section {
				padding-top: 160px;
			}

			+ .section.sm-space {
				padding-top: 30px;
			}

			+ .section:not([class*="-bg"]){
				background-color: #fff;
			}
		}


	//filter
	.filter{
		display: none;
	}

	//START TABS
	.tabs{
		text-align: center;
		.tab{
			background: rgba(0, 0, 0, 0.2);
			padding:24px 0;
			width:242px;
			display: inline-flex;
			margin:0 auto;

			&:first-of-type{
				border-top-left-radius: 50px;
				border-bottom-left-radius:50px;
			}

			&:last-of-type{
				border-top-right-radius: 50px;
				border-bottom-right-radius:50px;
			}


			&.active{
				background:#172B4D;

				svg{
					color:#DCDC36;
				}
			}

			.inner{
				display: inline-flex;
				margin: 0 auto;
			}

			svg{
				font-size: 20px;
				color:#fff;
				margin-right:10px;
			}

			&:hover{
				cursor: pointer;
			}

			span{
				color:#fff;
				@include font-semibold;
				font-size: 2.0rem;
			}
		}
	}

	.tab-content{
		background:$brand-color;
		border-radius: 5px;
		//padding:55px 0;
		margin-top:35px;

		.tab-container{
			display: none;
			margin:0 auto;

			&.active{
				display: block;
			}
		}

		form{
			border-radius: 5px;



		}
	}//END Hero banner with form


	/**
		START Shape banner
		*/
		&.hero-banner__overlay{
			background: $brand-color;
			position: relative;

			h1{
				text-align: left;
			}

			+ .section{
				padding-top: 0;
				top:-60px;
				background-color: transparent;
				z-index: 2;
				position: relative;

				.content-container{
					border-radius: 5px;
					margin:0 auto;
					background: #fff;

					.content-inner{
						max-width: 792px;
						margin:0 auto;
					}
				}



			}

			&.banner__services{
				
				.services__img{
					position: absolute;
					right: 0;
					bottom: 23px;
				}
				.container{
					background-image: url(../images/system/koru-lines-services.png);
					background-repeat: no-repeat;
					background-position: 100% 100%;
				}
				.breadcrumb {
					position: relative;
					z-index: 10;
					a{
						font-size: 1.4rem;
						@include font-medium;
					}
					li{
						svg{
							margin-left: 0;
						}
					}
				}
			}

			.banner__content{
				max-width: 792px;
				margin: 0 auto;
			}


			.banner-top{
				display: inline-flex;
				margin-bottom: 26px;

				.item{
					padding-right:40px;


					&:last-of-type{
						padding-right: 0;
					}

					span{
						color:#637381;
						line-height: 26px;
						@include font-bold;
					}
				}

				.icon{
					text-transform: uppercase;
					color:#637381;
					@include font-medium;


					span{
						font-size: 12px;
						letter-spacing: 1px;

					}

					svg{
						font-size: 20px;
						margin-right:10px;

					}

				}
			}


	}//END Shape Banner


	&.hero-banner__calendar{
		.banner__content{
			padding-bottom: 50px;
		}

		+ .section {
			top: -60px;
		}
	}

}




.content-banner{
	height: 80vh;
	min-height: 600px; 
	position: relative;
	text-align: center;    
	display: flex;
	align-items: center;
	&:before{
		content: "";
		background-color: rgba(198, 212, 210, 0.7);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}

	& > img{
		width: 100%;
		height: 100%;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		object-fit: cover;
	}
	.banner__content{
		padding-top: 60px;
		position: relative;
		z-index: 4;
		padding-bottom: 60px;
		max-width: 800px;
		margin: 0 auto;
		
	}
}

@include media-breakpoint-up(md) {
	span.search-filter-title{
		display: none;

	}

	.text-content{
		margin-left: 150px;
		margin-right: 150px;
		font-size: 1.8rem;
	}
}


@include media-breakpoint-down(md) {

	span.search-filter-title{
		color:#DCDC36;
		font-size: 1.2rem;
		text-transform: uppercase;
		@include font-semibold;
		letter-spacing: 0.4px;

		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: -30px;
		svg{
			margin-right: 5px;
		}
	}
	.hero-banner{
		.tab-content{
			position: relative;
			margin-top: 85px;
		}
	}

	.hero-banner {

		&.hero-banner.hero-banner__overlay{
			+ .section{
				.content-container{
					.content-inner{
						max-width: 100%;
						padding:0 20px;
					}
				}

			}
		}

		&.hero-banner__overlay.banner__services{
			.services__img {
				position: relative;
				display: block;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}

		&.hero-banner.hero-banner__calendar + .section {
			top: 0px;
		}

	}


}

@include media-breakpoint-down(sm) {
	.hero-banner {
		.text-content{
			text-align: center;
			margin: 0 auto;
		}



		.tabs {
			.tab {
				padding: 20px 20px;
				width: 146px;

				.inner{
					display: block;

					svg{
						margin-bottom: 10px;
						font-size: 26px;
					}

					p{
						font-size: 12px;
					}
				}
			}
		}

		.tab-content{
			margin-left:-15px;
			margin-right:-15px;
		}


		&.hero-banner.hero-banner__overlay{
			&:after{
				border-left: 55px solid transparent;
				border-right: 55px solid transparent;
				border-top: 30px solid $brand-color;
			}

		}

		&.overlap-lg .banner__content{
			padding-bottom: 60px;
		}
	}

	.hero-banner.hero-banner__calendar{
		.banner__content {
			padding-bottom: 15px;
			padding-top: 15px;
		}
	}

	.hero-banner.hero-banner--form + .section {
		padding-top: 100px;
	}

}
