.pagination-container{
	text-align:center;
	margin-bottom:-30px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover{
	background-color: rgba(198, 212, 210, 0.24);
	color: $brand-dark;
	border-color:transparent;
}


.pagination > li > a,
.pagination > li > span{
	color: $brand-secondary;
	@include font-regular;
	border-radius: 50px;
	margin:0 4px;
	margin-top:8px;
	border-color:transparent;
	background-color: transparent;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span{
	border-radius: 50px;
	height: 50px;
    width: 50px;
	display: flex;
    justify-content: center;
    align-items: center;
	margin-top: 0;
	border-color: transparent;
	background-color: transparent;
	border: 1px #C6D4D2 solid;
	svg{
		font-size: 20px;
	}
}

.pagination>li:first-child>a,
.pagination>li:first-child>span{
	margin-right:20px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span{
	margin-left:20px;
}

.pagination>li.disabled{
	svg{
		opacity: 0.5;
	}
}

@include media-breakpoint-down(sm) {
	.pagination > li > a, .pagination > li > span{
		margin:0 1px;
		margin-top:6px;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span,
	.pagination>li:last-child>a,
	.pagination>li:last-child>span{
		margin-left:10px;
		margin-right:10px;
        padding: 10px 13px;
	}
}