
$highlights-map : (

	"tint":(
		color:  $brand-tint,
		tone: 'light',
		light:  $brand-colorlightergrey,
		dark: $brand-colorgrey,
		),

	"blue":(
		color: $brand-color,
		tone 	: dark,
		light: $brand-light,
		dark:  $brand-dark,
		),

	"teal":(
		color: $brand-secondary,
		tone 	: dark,
		light: $brand-secondary-light,
		),

	"grey":(
		color: $brand-colorgrey,
		tone 	: dark,
		dark:  $brand-colordarkgrey,
		),
	"black" :(
		color: #000,
		tone: 'dark',
		pair: $brand-color,
		),
	
	"white" :(
		color: #fff,
		tone: 'light',
		pair: $brand-color,
		),
	);

@each  $highlight, $map in $highlights-map {
	// get values
	$color:  map-get($map, color);
	$light-color:  map-get($map, light);
	$dark-color:  map-get($map, dark);
	$tint: null;
	$tone:  map-get($map, tone);
	$pair: map-get($map, pair);
	
	@if map-has-key($map, tint){
		$tint : map-get($map, tint);
		} @else {
			$tint : lighten( map-get($map, color), 10%);
		}


		.#{$highlight}{
			&-bg{
				background-color:$color;
				&--overlay{
					background-color: rgba($color, 0.85);
				}


				@if $tone == 'dark'{
					p,h1,h3,h4,h5,h6 li{
						color: #fff;
					}

				}
				@if map-has-key($map, light){
					&--light{
						background-color:$light-color;
						&--overlay{
							background-color: rgba($light-color, 0.85);
						}
					}

				}
				@if map-has-key($map, dark){
					&--dark{
						background-color:$dark-color;
						&--overlay{
							background-color: rgba($dark-color, 0.85);
						}

					}			
				}
			}
		}


		h1,h2,h3,h4,h5,h6,p,li,span{
			&.#{$highlight}{
				color: $color;
			}
		}
		@if map-has-key($map, altName){
			$altName:  map-get($map, altName);

			.#{$altName}{
				h1,h2,h3,h4,h5,h6,p,li,span{
					color: $color;
				}
				&-bg{
					background-color:$color;
					@if map-has-key($map, light){
						&--light{
							background-color:$light-color;
						}
					}
					@if map-has-key($map, dark){
						&--dark{
							background-color:$dark-color;
						}
					}
				}
			}
			h1,h2,h3,h4,h5,h6,p,li,span{
				&.#{$altName}{
					color: $color;
				}
			}
		}
	}