
@import "./_main-nav-tools";
@import "./_main-nav-trigger";


$nav-link-color: #fff;




#main-nav.navbar.navbar-default {
	//dimensions
	width: 100%;
	min-height: 0;
	//positioning
	padding-top: 10px;
	margin-bottom: 0;
	align-items: flex-end;
	z-index:3;
	// style
	border: 0 none transparent;
	background-color:transparent;

	

	.navbar-header{
		width: 100%;
	}

	.navbar-nav{
		margin: 0;
		flex-direction:row;
		margin-bottom: 0;
		// to get the content to actually be displayed evenly;

		&:before,
		&:after{
			content:none;
		}

		.dropdown-toggle{
			overflow: hidden;
			&:after{
				content: "\f078";
				@include fontawesome-regular;
				display: none;
			}
			.fa-chevron-down{
				opacity: 0.3;
				margin-left: 3px;
				margin-top: 2px;
				font-size: 0.8rem;
				transform:rotate(0deg);
				float: right;
			}
		}



		&.navbar-tools{

			.dropdown{
				.dropdown-menu{
					& > li {
						& > a{
							font-size: 1.8rem;
						}
					}

				}
			}


			li {
				position: relative;
				font-size: 1.4rem;

				transition: opacity 0.2s ease-out;

				&.active{
					& > a{
						border-radius: 4px 4px 0px 0px;
						color: #fff;
						background-color: rgba(42, 42, 42, 0.6);
					}
				}

				& > a{
					padding: 15px 10px;
				}

				.btn{
					color: $brand-secondary;
					svg{
						margin-right: 5px;
					}
					&:hover,
					&:focus{
						background-color: $brand-secondary;
						color: #fff;
					}
					
				}

				& > .active > a,
				& > .active > a:hover,
				& > .active > a:focus {
					// BS override
					background: transparent;
				}

				& > a:not(.btn) {

					display: block;
					transition: color 0.2s ease-out, background-color 0.2s ease-out;
					display: flex;
					align-items: center;
					color: rgba(243,248,247,0.66);

					.fa-search{
						margin-right: 10px;
						color: $brand-quarterny;
					}

					@include font-semibold;

					&:hover {
						color: $brand-quarterny;
					}
				}
			}
		}

		&.navbar-main{

			li {
				position: relative;
				font-size: 1.8rem;
				transition: opacity 0.2s ease-out;

				&.active{
					& > a{
						border-radius: 4px 4px 0px 0px;
						color: #fff;
						background-color: rgba(42, 42, 42, 0.6);
					}
				}

				

				.btn{
					color: $brand-secondary;
					padding: 10px 15px;
					font-size: 1.6rem;
					margin-left: 10px;
					margin-right: 0;

					svg{
						margin-right: 5px;
					}
					&:hover,
					&:focus{
						background-color: $brand-quarterny;
						border-color: $brand-quarterny;
						color: #fff;
					}
					
				}

				& > .active > a,
				& > .active > a:hover,
				& > .active > a:focus {
					// BS override
					background: transparent;
				}

				& > a:not(.btn) {
					display: block;
					transition: color 0.2s ease-out, background-color 0.2s ease-out;
					color: #fff;
					display: flex;
					align-items: center;
					@include font-semibold;

					&:hover,
					&:focus {
						color: $brand-quarterny;
					}

				}
			}
		}

		.dropdown{
			.close {
				display: none;
				position: absolute;

				opacity: 1;
				text-shadow: none;
				&:hover{
					color: $brand-secondary;
				}
			}
			&.open {

				> .dropdown-menu {
					animation-name: slidenavAnimation;
					animation-duration:.3s;
					animation-iteration-count: 1;
					animation-timing-function: ease;
					animation-fill-mode: forwards;

					-webkit-animation-name: slidenavAnimation;
					-webkit-animation-duration:.3s;
					-webkit-animation-iteration-count: 1;
					-webkit-animation-timing-function: ease;
					-webkit-animation-fill-mode: forwards;

					-moz-animation-name: slidenavAnimation;
					-moz-animation-duration:.3s;
					-moz-animation-iteration-count: 1;
					-moz-animation-timing-function: ease;
					-moz-animation-fill-mode: forwards;
				}
				@keyframes slidenavAnimation {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}
				@-webkit-keyframes slidenavAnimation {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}
			}
			.dropdown-menu{
				color: #2b2b2b;
				padding-top: 50px;
				padding-bottom: 50px; 

				& > li {
					
					& > a {
						display: block;
						white-space: normal;
						&:focus,
						&:hover {
							text-decoration: none;
							color: $link-highlight;
						}

						&:before{
							content: "\f178";
							@include fontawesome-light;
							display: none;
						}
						.fa-long-arrow-right{
							position: absolute;
							left: 0;
						}
						p{
							color: #404041;
						}
					}
					&:hover {
						background-color: transparent;
						color: $link-highlight;
					}
				}

			}
		}

		&.opened{
			li:not(.open){
				opacity: 0.2;
			}
		}
	}
}


@include media-breakpoint-up(xl) {



	
	.mobile-menu-actions,
	.mobile-buttons{

		display: none;

	}





	#main-nav.navbar.navbar-default {

		display: grid;

		.navbar-main{
			order: 2;
		}

		.close{
			display: none;
		}

		.navbar-nav {

			display: flex;
			justify-content: flex-end;
			width: 100%;
			flex-wrap:wrap;

			&.navbar-tools{

				.dropdown{

					&.open{
						.dropdown-toggle{
							background-color: transparent;
							color: $brand-quarterny;
						}
						& > .dropdown-toggle{
							.fa-chevron-down{
								color: $brand-quarterny;
								transform:rotate(180deg);

							}
						}
					}

					.dropdown-toggle{
						display: flex;
						align-items: center;
						overflow: hidden;
						&:after{
							content: "\f078";
							@include fontawesome-regular;
							display: none;
						}
						.fa-chevron-down{
							opacity: 0.3;
							margin-left: 5px;
							font-size: 1.0rem;
							transform:rotate(0deg);
							float: right;
						}
					}

					.dropdown-menu{
						border-top: 5px $brand-quarterny solid;
						min-width: 250px;
						padding: 0 0;
						border-radius: 4px;
						li{
							&:nth-last-child(1){
								a{
									border-bottom: 0;
								}
							}
							a{
								color: $brand-secondary;
								padding: 20px 0 15px 0;
								margin: 0 20px;
								display: flex;
								align-items: center;
								border-bottom: 2px #E0E3E8 solid;



								&:hover,
								&:focus{
									background-color: transparent;
									color: $brand-quarterny;
								}
								&:before{
									content: "\f178";
									@include fontawesome-light;
									display: none;
								}
								.fa-long-arrow-right{
									margin-right: 5px;
									position: relative;
								}
							}
						}
					}
					
				}
			}

			&.navbar-main{
				
				& > li{
					& > a{
						padding: 15px 6px;
					}
					&.dropdown{
						& > a{
							&:hover{
								color: $brand-quarterny;
							}
						}
						&.open{

							& > a{
								z-index: 4;
								position: relative;
								color: $brand-quarterny;
								background-color: transparent;
								overflow: visible;
								.nav-line{
									content: "";
									position: absolute;
									left: 0;
									bottom: -11px;
									height: 5px;
									width: 100%;
									overflow: inherit;
									z-index: 3;
									border-radius: 44px;
									background-color: $brand-quarterny;
								}
								
							}

							& > .dropdown-menu:not(#mega-nav__menu){
								display: grid;
								grid-template-columns:1fr 1fr 1fr;
								grid-column-gap: 40px;
							}
							& > .dropdown-toggle{
								.fa-chevron-down{
									opacity: 1;
									transform:rotate(180deg);

								}
							}
						}
					}
				}


				.dropdown{
					position: static;

					.dropdown-menu{
						z-index: 3;
						position: absolute;
						width: 100%;
						background: $brand-tint;
						top: 118px;
						color: #000;
						box-shadow: none;
						border: 0;
						border-radius: 0;

						& > li {
							overflow: hidden;
							& > a {
								padding: 15px 20px;
								
								p{
									white-space: normal;
									transition: color 0.2s ease-out;
									margin-bottom:0;
								}
								&:focus,
								&:hover {
									color: $brand-quarterny;
									p{
										color: $brand-quarterny;
									}
								}
							}

							&:hover {
								background-color: transparent;
								color: $brand-color;
							}
						}

						&::before,
						&::after{
							content: '';
							background: $brand-tint;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
						}
						&::before{
							left: -100%;
						}
						&::after{
							right:-100%;
						}
						&.open{
							display: block;
						}
						.nav-item{
							margin-bottom: 2.5rem;
							overflow: hidden;
							max-width: 40rem;
							display: block;
							-webkit-column-break-inside: avoid;
							page-break-inside: avoid;
							break-inside: avoid;

							p{
								display: block;
								margin-top: 15px;
								font-size: 1.4rem;
								@include font-light;
							}
							& > a{
								.fa-long-arrow-right{
									top: 22px;
									font-size: 1.2rem;
								}
								
								color: $brand-secondary;
								@include heading-bold;
							}
						}
						.dropdown-heading{
							display: none;
						}

					}
				}
			}
		}
	}
}

@include media-breakpoint-down(lg) {

	.mobile-menu-actions{
		margin-left: 25px;
		margin-right: 25px;
		
		.mobile-buttons{
			padding-top:20px;
			padding-bottom:20px;
		}
	}

	.mobile-buttons{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}


	.header-wrap{
		padding-top: 0;
		background: #172B4D;		
		.navbar-brand{
			height: 50px;
		}
	}

	.top-nav{
		padding-top: 10px;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 11;

	}

	#main-nav.navbar.navbar-default {

		align-items: center;
		position: fixed;
		background: $brand-color;
		left: 0;
		top: 0;
		margin: 0;
		height: 100vh;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.2s , opacity 0.2s ease-in-out;
		overflow-y:scroll; 
		padding-top: 80px;
		border-radius:0;
		.hidden-sm{
			display: none;
		}

		.btn{
			font-size: 1.6rem;
			width: 100%;
			svg{
				margin-right: 5px;
			}
			&:hover,
			&:focus{
				background-color: $brand-quarterny;
				border-color: $brand-quarterny;
				color: #fff;
			}
			
		}

		.navbar-nav {
			width: 100%;
			flex-direction: column;
			padding-top:0;



			& > li {
				width:100%;
				
				& > a{
					white-space: normal;
					padding: 20px 10px;
					line-height: 1.2em;
					margin-left: 25px;
					margin-right: 25px;
					border-bottom: 1px rgba(224, 227, 232, 0.12) solid;

					&:not(.btn){
						justify-content: space-between;
					}
					&.search-modal{
						justify-content: normal;
					}

					&:active,
					&:hover,
					&:focus,
					&:hover:before,
					&:focus:before {
						color: #fff;
						background: transparent;
					}
				}

				&.close{
					position: absolute;
					right: 25px;
					bottom: 10px;
					display: block;
					// styles
					color: $link-highlight;
					text-shadow: none;
				}
			}


			.dropdown{
				.dropdown-menu {
					position: relative;
					background-color: transparent;
					text-align: left;
					border: 0;
					visibility: hidden;
					overflow: hidden;
					box-shadow: none;
					float: none;
					padding: 0;
					padding-bottom: 15px;

					.nav-item{
						font-size: 20px;
						padding-left: 15px;
						border-bottom: 0;
						overflow: hidden;
						& > a{
							.fa-long-arrow-right{
								top: 20px;
								font-size: 1.2rem;
							}
						}

						a{
							padding-top: 15px;
							padding-left: 5px;
							padding-bottom: 15px;
							font-size: 1.8rem;
						}
						p{
							font-size:1.2rem;
							margin-top: 10px;
							color: rgba(99,115,129,0.7);
						}

					}

					.close {
						display: block;
					}
				}

				&.open{
					background: $brand-tint;
					border-top: 4px $brand-quarterny solid;
					& > a{
						border-bottom: 1px rgba(224, 227, 232, 1) solid;

					}

					& > .dropdown-menu {
						display: block;
						left: 0;
						visibility: visible;
						margin-left: 25px;
						margin-right: 25px;
						padding: 10px;
						li > a:not(.btn){
							color: $brand-secondary;
							background:transparent;
							&:hover{
								color: $brand-quarterny;
							}


						}
						.nav-item{
							&:hover{
								color:#fff;

							}
						}
					}

					& > a:not(.btn){
						color: $brand-color;
						background:transparent;
					}
				}
			}
		}
	}

	.navigation-is-open{
		overflow-x: hidden;
		#main-nav.navbar.navbar-default {
			opacity: 1;
			visibility: visible;
			z-index: 10;
			display: block;


			.navbar-nav {
				li{
					opacity: 1;

				}
				
			}
		}
	}
}
