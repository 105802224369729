/////
//search results
/////


#search-modal{
	margin-bottom: 2em;
	margin-top: 1em;

	
	&.fade{
		.modal-dialog{
			transform: translate(0, -50px);
		}
		&.in .modal-dialog{
			transform: translate(0, 0);
		}
	} 

	.modal-dialog{
		display: flex !important;
		align-items: center;
		height: 100%;
	}

	.modal-header{
	    margin: 0 15px 40px 15px;
	    padding-bottom: 15px;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
		h2{
			@include heading-bold;
			color:$brand-color;
			margin-bottom: 0;
			margin-top: 0;
		}
		&::before,
		&::after{
			display: none;
		}

	}
	.modal-content{
		padding: 25px 15px;
		background-color: $brand-tint;
		border: 0;
		width: 100%;
		.modal-body{
			background-color: transparent;
			padding:0 0 20px;
		}
	}

	.input-group{
		display: flex;

	}

	.form-group{
		background-color: #ffffff;
	    border-radius: 30px;
	    padding: 10px;
		.btn{
			margin-bottom: 0;
		}
	}

	.form-control{
		padding: 25px 8px;
		border:0;
		border-radius: 0 !important;;
    	background: transparent;
		font-size: 1.8rem;

		@include font-light; 
		&:focus{
			box-shadow: none;
			outline: 0;
		}

		&::placeholder{
			font-style: italic;
		}
	}
	form{
		margin-top: 2em;
		padding: 0 2em;
		.input-group-btn{
			border: none;
			display: inline-block;
			width: auto;

		}
		.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group{
			position: relative;
		}
	}

}
