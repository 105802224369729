.nav-tabs.nav-justified {
	padding: 0;
	border-radius: 4px 4px 0 0;
	background: #fff;
	.nav-title{
		padding: 10px 15px 10px 0;
	}
	& > li{
		font-size: 25px;

		& >	a{
			padding-top: 30px;
			padding-bottom: 20px;
			color: #9B9B9B;
			border:0;
			border-bottom:5px solid #D2D2D2;
			@include font-medium();
			transition:color 0.2s ease-out, border-color 0.2s ease-out;
			cursor: pointer;
			&:hover, 
			&:focus{
				color: darken($brand-color, 20%);
				background: transparent;
			}
		}
		&.active{
			& > a{
				border:0;
				border-bottom:5px solid  $brand-color;
				color: $brand-color;
				&:hover,
				&:focus{
					padding-top: 30px;
					padding-bottom: 20px;
					color: #9B9B9B;
					border:0;
					border-bottom:5px solid #D2D2D2;
					@include font-medium();
				}
			}
		}
	}

}

@include media-breakpoint-down(sm) {

	.nav-tabs.nav-justified {
		border-radius: 4px 4px 0 0;
		display: flex;
		align-itmes:flex-end;
			& > li{
				font-size: 20px;
				float: left;
				width: 33.333%;
				margin-bottom:0;
				border-radius: 0;
				display: flex;
				align-items:flex-end;
				a{
					margin-bottom: 0;
					border-radius: 0;
					display: inline-block;
					width: 100%;
					padding: 15px 10px;
				}
			}
		}
}	
