.nav-pills{
	border-bottom: 0.8px solid #E2E2E2;
	padding: 20px 0;
	.nav-title{
		padding: 10px 15px 10px 0;
	}
	li{
		i{
			color: #D2D2D2;
		}
		a{
			color: #9B9B9B;
			@include font-medium();
			&:hover, 
			&:focus,
			&.active{
				color: $brand-color ;
				background: transparent;
			}
		}
	}
}

@include media-breakpoint-down(md) {

	.nav-pills{
	 .nav-title{
	 	font-size: 20px;
	 }
	 li{
	 	padding:0;
	 	float: none;
	 	margin-left: 0;
	 	a{
	 		padding-top: 0;
	 		padding-left: 0;
	 	}
	 }
	}

}