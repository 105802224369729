


.card{
	margin-bottom: 50px;
   a{
      
  

	&:hover{
      color: $brand-color;
		.card__title{
			h3{
				color: $brand-color;
			}
		}
	}
 }

	.card__img{
		text-align: center;
		background: #F5F5F5;
		position: relative;

	}
	.card__subheading{
		margin-bottom: 15px;
		position: relative;

		span{
			@include heading-regular;
			font-size: 1.8rem;
			color: #667380;
			margin-bottom: 10px;
			margin-right: 10px;
			&:nth-last-child(1){
				margin-right: 0;
			}
		}
	}


	.card__info{

		ul{ 
			margin-top: 20px;
			display: grid;
			grid-template-columns: 50% 50%;
			column-gap: 10px;
			row-gap: 10px;
			padding-left: 0;
			list-style: none;
			li{
				display: grid;
				align-items: center;
				padding-left: 35px;
				position: relative;
				
				.list__icon{
					left: 0;
					position: absolute;
					svg{
						color: $brand-colorlightergrey; 
						margin-right: 10px;
						font-size: 2rem;
					}
				}
				.list__content{
					& > span{
						display: inline-grid;
						font-size: 1.4rem;
						color: $brand-colorgrey;
						@include font-medium;
						& > span{
							font-size: 0.8rem;
							text-transform: uppercase;
							display: block;
							font-family: "raleway", Helvetica, Arial, sans-serif;
							font-weight: 700;
							color: rgba(96, 96, 96, 0.5);
							padding-right: 5px;
						}
					}
				}
			}
		}

		& > span{
			// display: inline-block;
   			// width: 50%;
   			// margin-bottom: 15px;
   			// padding-right: 10px;

   			font-size: 1.4rem;
   			@include font-medium;
   			svg{
   				color: $brand-colorlightergrey; 
   				margin-right: 10px;
   				font-size: 2.2rem;
   			}
   			&.cpdPoints{
   				span{
   					background: $brand-tertiary-light;
   					display: inline-block;
   					margin-right: 5px;
   					padding: 5px;
   					border-radius: 50%;
   					color: $brand-secondary;
   					@include font-bold;
   				}
   			}
   			color: $brand-colorgrey;
   		}
   	}

      .card__listinfo{
         padding-left: 0;
         margin-left: 4.5em;
          margin-top: 20px;
         li{
            list-style: none;
            font-size: 1.4rem;
            margin-bottom: 20px;
            a{
               color: #404040;
            }
            svg{
               path{
                  color: $brand-secondary;
               }
               font-size: 2.0rem;
               margin-right: 5px;
            }

         }
         .card__membership{
            font-size: 1.2rem;
            text-transform: uppercase;
            @include font-semibold;
         }
      }


   	.card__date{
   		@include font-bold;
   		color: $brand-colordarkgrey;
   		font-size: 1.4rem;
   		margin-right: 30px;
   	}

      .card__role{
         @include font-bold;
         color: $brand-colordarkgrey;
         font-size: 1.4rem;
         margin-right: 30px;
         svg{
            font-size: 1.6rem;
            margin-right: 5px;
           
         }

		  &.dark-bg{
			  color: $brand-colorgrey
		  }
      }

   	.card__extraInfo{
   		color: $lighter-grey;
   		font-size: 1.2rem;
   		@include font-medium;
         span{
            color: $lighter-grey;
            font-size: 1.2rem;
            @include font-medium;
            margin-right: 10px;
            &:nth-last-child(1){
               margin-right: 0;
            }
         }
   	}

   	.card__author{
   		@include sub-heading-medium;
   		font-style: italic;
   		font-size: 1.5rem;
   		margin-bottom: 20px;
   		display: block;
   		color: $brand-colorgrey;
   	}
   	.card__type{
   		font-size: 1.2rem;
         letter-spacing: 0.4px;
   		text-transform: uppercase;
   		@include font-semibold;
   		color: $brand-colorgrey;
   		margin-bottom: 5px;
   		margin-right: 10px;
   		svg{
   			font-size: 1.4rem;
   			margin-right: 5px;
   		}
   		
   		&:nth-last-child(1){
   			margin-right: 0;
   		}
   	}

   	.card__title{
   		color: $brand-secondary;

   		h3{
   			transition:color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
   			margin-bottom: 5px;
   			margin-top: 10px;
   			line-height: 1.3em;
   			color: $brand-secondary;
   		}
   		small{
   			font-size: 16px;
   			line-height: 1;
   			@include font-light;
   		}
   	}

   	.card__block{

   		p{
   			color: $brand-colorgrey;
   			margin-bottom:0;
   			overflow: hidden;
   			text-overflow: ellipsis;
   			display: -webkit-box;
   			-webkit-line-clamp: 4; 
   			-webkit-box-orient: vertical;
   		}
   		small{
   			color: #9B9B9B;
   			font-size: 16px;
   			@include font-medium;
   			margin-right: 15px;
   			display:block;
   		}
   	}
}



/// cards fror Submissions / development

.card--search{
	padding-bottom: 20px;
	border-bottom: 1px #e4e4e4 solid;
	margin-bottom: 20px; 
	background-color: transparent;
	.card__content{
		padding-left: 15px;
		padding-right: 15px;
	}
}



@include media-breakpoint-down(md) {

	.card{
		.card__block{
			p{
				max-width: 100%;
			}
		}

	}

}

