
footer{


	.footer-top{
		border-bottom: 2px rgba(223, 227, 232, 0.1) solid;
	    margin-bottom: 50px;
	    padding-bottom: 50px;
	    
	    ul{
	    	margin-left: 5.5em;
	    	li{
	    		margin-top: 30px;
	    		margin-bottom: 30px;
	    		a{
	    			.fa-li{
	    				padding-right: 15px;
	    			}
	    			svg{
	    				color: $brand-secondary;
	    				font-size: 2.0rem;
	    			}
	    		}
	    	}
	    }
	}

	padding: 75px 0 100px;
	position: relative;
	@include link-color($brand-color, $brand-color);

	background-color: $brand-color;
	color: #fff;

	@include link-color(#fff, $brand-secondary);

	.legal-links{
			display: flex;
    	justify-content: flex-end;
			a{
				color: rgba(250, 251, 252, 0.3);
				font-size: 1.6rem;
				@include heading-bold;
				display: inline-block;
				margin-right: 30px;
				&:hover,
				&:focus{
					color: $brand-secondary;
				}
			
			}
		}
		
	a{
		@include font-medium;
		&.more{
			color: #fff;
		}
	}
	p{ 
		color: #fff;
	} 

	.contact-container{
		padding-top: 2rem;
		ul{
			justify-content:space-between;
			margin-bottom: 0;
			li{
				font-size: 2rem;
				margin-bottom: 0;
				svg{
					margin-right: 1.5rem;
				}
			}
		}
		
	}

	.sponsor-container{
		display: flex;
		border-top:1px solid rgba(#fff, 0.3);
		border-bottom:1px solid rgba(#fff, 0.3);
		margin-top: 4rem;
		margin-bottom: 4rem;
		padding-top: 4rem;
		padding-bottom: 1rem;

		flex-wrap: wrap;
		justify-content: space-between;
		align-items:center;



		.sponsor-title{
			margin-top: -68px;
			flex-basis:100%;

			p{
				padding-right: 2rem;
				background-color:#8A8A8A;
				display: inline-block;
			}
		}
		.sponsor-logo{
			margin-right: 3rem;
			margin-bottom: 3rem;
		}
		img{
			max-width: 18.5rem;
			max-height: 5.5rem;
		}
	}
}


@include media-breakpoint-down(md) {

	footer{
		padding: 75px 0;
		.footer-top{
			margin-bottom: 15px;
    		padding-bottom: 15px;
			border-bottom: 0;
			.col-xs-12{
		    	&:after{
		    		content: "";
				    display: block;
				    height: 2px;
				    width: 100%;
				    background-color: rgba(223, 227, 232, 0.1);
		    	}
	    	}
		}
		
		.legal-links {
		    display: block;
		    margin-top: 30px;
		    a {
		    	@include font-medium;
		    	display: block;
			    padding: 20px 0;
			    border-top: 1px rgba(0, 0, 0, 0.2) solid;
			}
		}
	}

}

@include media-breakpoint-down(md) {

	footer{
		padding: 50px 0;
	}

}

