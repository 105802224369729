
.home-cta{
	background-image: url("../images/system/background-hands.png");
	background-repeat: no-repeat;
	background-position: right top;
	padding: 200px 0;
	h1{
		margin-top: 0;
	}
}


.home-intro{
	background-image: url("../images/system/koru-lines.png");
	background-repeat: no-repeat;
	background-position: 35% 50%;
}

.home-tile{
	display: grid;
	align-items: end;
	&_content{
		position: relative;
		h1,h2,h3,h4,h5,h6{
			color: #fff;
		}
		h2{
			margin-bottom: 0;
		}
		h3{
			margin-top: 0.5em;
		}
		p{
			@include font-medium;
			font-size: 1.4rem;
			line-height: 1.5em;
			letter-spacing: 0.35px;
		}
	}


}



.statement-text{

	padding-top:130px;

	h1{
		font-size: 6.8rem;
		color:$brand-color;
		margin-bottom: 30px;
	}
	.lead{
		font-style: italic;
		color: $brand-grey-light;
		margin-bottom: 50px;
	}
}

.list-call-to-action{
	@include list-reset;

	li{
		position:relative;
		padding-left:45px;
		font-size:2.4rem;
		@include sub-heading-medium;
		margin-bottom:25px;
		font-style: italic;
		strong{
			@include heading-bold;
			font-style: italic;
		}
		a{
			color: #606060;
			&:hover,
			&:focus{
				color: #fff;
			}
		}
		&:before{
			content:'\f178';
			@include fontawesome-light();
			display: none;

		}
		.svg-inline--fa{
			position:absolute;
			left: 0;
			top: 0;
			color: #fff;
			font-size: 3.7rem;
			line-height: 2.8rem;
		}
	}
}

.home-spacing{
	padding-bottom:100px;
	h2{
		font-weight: 800;
	}

	.section-heading{
		margin-bottom: 40px;
		h1{
			margin-bottom: 0;
		}

		a.more{
			&--feature{
				margin-bottom: 0;
				margin-left: 20px;
			}
		}
	}
}

.event-list{
	margin-bottom: 3rem;
	&__item{
		display: flex;
		align-items:flex-start;
		padding:3rem 1.5rem;
		border-top:1px solid #fff;
		&:last-of-type{
			border-bottom:1px solid #fff;
		}
		.date{
			color: $brand-color;
			text-align: center;
			margin-right: 40px;

			span{
				display: block;
				margin-bottom: 0;

			}
			.month{
				font-size:2rem;
				@include heading-bold;
			}
			.day{
				font-size:4.4rem;
				@include heading-regular;
				line-height: 1em;
			}
		}
		.content{
			h3,p{
				color:#fff;
			}
			h3{
				font-size: 2rem;
				margin-bottom: 10px;
				@include heading-bold;
			}
			p{
				font-size: 1.4rem;
				@include sub-heading-medium;
				max-width: 35rem;
				margin-bottom: 1rem;
				a{
					color:$brand-grey-dark;
					font-style:italic;
					&:hover{
						color:#fff;
					}
				}
			}
		}
		.location{
			font-size: 1.4rem;
			color:$brand-grey-dark;
			display: inline-flex;
			&:before{
				@include  fontawesome-regular();
				content:'\f041';
				display: none;
			}
			svg{
				font-size: 2rem;
				margin-right: 0.5rem;
				display: inline-block;
			}
			span{
				font-size: 1.4rem;
			}
		}
	}
}




@include media-breakpoint-down(md) {

	.home-cta{

	}

}

@include media-breakpoint-up(sm) {

	.home-tile{
		position: relative;
		border-radius: 10px;
		padding: 180px 30px 30px 30px;
		background-color: $brand-secondary;
		background-image: url("/images/content-image-1.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		height: 100%;

		&_content{
			position: relative;


		}

		&:before{
			content: "";
			background: linear-gradient(180deg, rgba(63, 161, 151, 0) 20%, #3FA197 70%);
			height: 100%;
			width: 100%;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.btn.btn-primary{
			background: #fff;
			border: 1px solid #C6D4D2;
			color: $brand-secondary;

			&:hover,
			&:active,
			&:focus,
			&:not(:disabled):not(.disabled):active:hover,
			&:not(:disabled):not(.disabled):active:focus {
				background-color: $brand-color;
				border-color: $brand-color;
				box-shadow: none;
				color: #fff;
			}
		}
	}

}



@include media-breakpoint-down(sm) {


	.home-cta{
		background-image: url("../images/system/background-hands-flipped.png");
		padding: 400px 0 30px 0;
		background-size: 140em;
		background-position: 56% 85%;
	}

	.home-intro{
		background-position: 220% 50%;
	}

	.home-tile{
		background-image: none !important;
		.btn{
			width: 100%;
		}

	}

}


