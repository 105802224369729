.people{


  .top-section{
    position: relative;

    .item{
      @include font-medium;
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      margin-right:35px;

      svg{
        color:$brand-secondary;
        font-size: 12px;
        margin-right:5px;
        display: none;

        &.is-open{
          display: none;
        }

        &.is-close{
          display: inline-block;
        }

        &.default{
          display: inline-block;
        }
      }

      &.disabled{
        opacity: 0.5;

        &:hover{
          cursor:disabled;
        }
      }


      &.current{
        svg.is-open{
          display: inline-block;
        }

        svg.is-close{
          display: none;
        }
      }
    }

    .update{
      @include font-bold;
      font-size: 14px;
      position: absolute;
      line-height: 20px;
      right:0;
      top:50px;
    }

  }


  .login-section{

    h6{
      margin-bottom: 60px;
    }

    .login-section-inner{
      padding:20px 30px;
      background: $brand-color;
      display: inline-flex;
      width:100%;
      border-radius: 5px;
      position: relative;
    }


    svg{
      color:$brand-quarterny;
      font-size: 32px;
      margin-right:20px;
    }

    span{
      @include font-bold;
      font-size: 14px;
      color:#fff;
      line-height: 30px;
    }

    a.btn{
      position: absolute;
      right:20px;
      top:50%;
      margin-top:-22px;
    }

  }


  .info-section{
    .block{
      max-width: 225px;
    }

    .title{
      @include font-bold;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .service-section{
    .title{
      @include font-medium;
      font-size: 24px;
      color:$brand-secondary;
      margin-bottom: 20px;
    }
  }


  .accordion-section{
    h3{
      @include font-light;
      color:$brand-secondary;
    }

    .panel-body__details{
      width:100%;
    }

    svg{
      font-size: 20px;
      color:$brand-secondary;
      margin-right:10px;

    }

    p.location{
      margin-bottom: 0;
    }

    span{
      color:#404041;
      font-size: 14px;
      line-height: 14px;
      @include font-regular;

      &.link{
        @include font-bold;
      }
    }

    .item{
      margin-top: 20px;
    }

    .title{
      display: inline-flex;


      svg{
        font-size: 22px;
        color:#232B35;
      }

      span{
        line-height: 24px;
        @include font-bold;
      }
    }

    .name{
      @include font-medium;
      font-size: 24px;
      color:$brand-secondary;
      padding: 5px 0;
    }


    .bottom{
      padding-top:15px;

      .small{
        display: inline-flex;
        width: 100%;
        padding:5px 0;

        &:last-of-type{
          padding-bottom: 0;
        }

      }
    }

  }


}

@include media-breakpoint-down(md) {
  .people-detail,.people-detail-login{
    .bottom{

     margin-bottom: 40px;
   }
 }

 .people{


   .login-section{

    .login-section-inner{
      display: block;
    }

    a.btn{
      position: relative;
      right:auto;
      top:auto;
      margin-top: 20px;
    }
  }
}

}

@include media-breakpoint-down(sm) {
  .people-detail,.people-detail-login{
    .top-section{
      .item{
        display: block;
        padding: 15px 0;
        border-top: 1px solid $border-color;
      }

      .update{
        position: unset;
        padding-bottom: 8px;
      }


    }

    .login-section{
      svg{
        font-size: 20px;
        margin-right:10px;
      }

      span{
        padding-right:0;
        line-height: 16px;
      }
    }

    .accordion-section {
      span{
        line-height: 18px;
      }

      .bottom{

        .small{
          width:100%;
        }
      }

    }
  }
}

@include media-breakpoint-down(xs) {
  .people-detail,.people-detail-login{

  }
}