.members-only{
	span{
		margin-right: 5px;
	}
}

.directory-top{
	.directory-buttons {
	    display: flex;
	    justify-content: space-between;
	}
}

#event-cards{
	margin-top: 60px;
}


#filter-form{

	.directory-filter{
		//display: flex;
		//margin-top: 30px;
        //
		//.vue-input{
		//	width: 70%;
		//	flex-basis:70%;
        //
		//}
		//.v-select{
		//	width: 30%; 
		//	flex-basis:30%;
		//	background: #e8f4f6;
		//}
	}

	.additional-filters{
		display: flex;
		justify-content: space-around;
		align-items:center;
		flex-wrap: wrap;
		.form-group{
			width: 30%;
		}
	}
	.btn{
		display: block;
		margin: 0 auto;

		&.btn-link{
			color:#606060;

			&:hover,
			&:focus{
				color:#606060;
			}
		}
	}

	[type="submit"]{

	}
}


.line-heading{
	margin-bottom: 40px;
	h3{
		text-align: center;
		text-transform: uppercase;
		align-items: center;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 20px;
		font-size: 14px;
		color: $brand-color;

		&:after,&:before {
			content: " ";
			display: block;
			border-bottom: 1px solid $brand-light;
		}
	}

}



.card-container{
	h4{
		color:$gray-text;
	}
	.row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;


		[class^="col-"]{
			margin-bottom: 60px;
			
			&:nth-last-child(1){
				margin-bottom: 0;
			}

			.card{
				width: 100%;
				height: 100%;
			}
		}

	}
}

.people-directory,.members-directory,.service-directory{

}

.card-container{
	
	.row{
		
		[class^="col-"]{
			&:nth-last-child(1){
				margin-bottom: 60px;
			}
		}

	}
}


@include media-breakpoint-up(sm) {


}


@include media-breakpoint-down(sm) {


.directory-top{
	.directory-buttons {
	    display: block;
	    text-align: center;
	    .btn{
	    	margin-top: 30px;
	    }
	}
}

	.card-container{
		h4{

		}
		.row{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			[class^="col-"]:first-child{
			
					.card{
						border-top:0;
					}
				
			}


			[class^="col-"]{
				margin-bottom:30px;

				.card{
					height: auto;
					padding-top:30px;
				}

				.card{
					border-top:1px solid #E0E3E8;
				}
			}

		}
	}

}