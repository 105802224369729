
.social-container{

	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	align-items: center;

	.socials{
		margin-bottom: 0;
		padding-left: 0;
    	list-style: none;
		li{
			display: inline-grid;
		    margin-right: 15px;
		    align-items: center;
		    svg{
		    	color: $brand-quinary;
		    }
		    h5{
		    	margin-bottom: 0;
		    }
		}
	}
}


#scroll-page-top{
	color: $brand-colorlightergrey;
	background: transparent;
	border:0;
	box-shadow: 0;
	transition: opacity 0.2s ease-out;
	padding:0;
	@include heading-bold;
	text-transform: uppercase;
	font-size: 1.2rem;
	display: flex;
    align-items: center;
	svg{
		margin-right: 10px;
		color: $brand-quinary;
		font-size: 1.6rem;
	}
}



@include media-breakpoint-down(sm) {

	.social-container{
		display: block;
		justify-content: space-between;
	}

	#scroll-page-top{
		margin-top: 30px;
	}	
	
}