
.step-counter-container {
	text-align: center;
}


ol.step-counter-list{
	margin-bottom: 0;
	li.step{
		display: inline-block;
		padding-left: 0;
		text-align: center;
		padding-top: 80px;
		margin-bottom: 0;
		min-width: 150px;
		color: #D2D2D2;
		@include font-medium;
		&:before{
			left: 50%;
			transform:translateX(-50%);
			background: #fff;
			color: #D2D2D2;
			border:2px solid #D2D2D2;
			z-index: 1;
		}
		&.active{
			color: $brand-color;
			&:before{
				background: $brand-color;
				color: #fff;
				border:0;
			}
			
		}
		&.completed{
			color: #D2D2D2;
			&:before{
				background: #D2D2D2;
				border:0;
				color: #fff;
			}
		}
		& + li.step{
			&:after{
				content: '';
				height: 1px;
				background: #D2D2D2;
				width: 100%;
				position: absolute;
				left: -90px;
				top: 35px;
				z-index: 0;
			}
		}

	}
}