

.card.card--news{
	&.card--img{
	 	.card__content{
	 		padding-bottom: 30px;
		}
	}

	a:hover a.more, a:focus a.more, a.more:hover, a.more:focus {
    color: $brand-color;
}
	
}

