
@include media-breakpoint-up(lg) {

	#main-nav-trigger {
		display: none;
	}

}
@include media-breakpoint-down(lg) {



	.navigation-is-open{
		#main-nav-trigger {
			    opacity: 0.5;
			&:before {
			    opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}

	#main-nav-trigger {

		position:absolute;
		right: 15px;
		top: 18px;
		//dimensions
		height: 40px;
		width: 40px;
		border-radius: 50%;
		padding: 0;
		//style
		background-color:transparent;
		border:0;
		// 
		display: inline-block;
		transition: all 0.5s ease-out ;
		z-index: 999;

		&:before {
		    content: "Menu";
		    opacity: 1;
		}
		&:after{
			content: "Close";
			opacity: 0;
		}

		&:before,
		&:after{
			@include font-semibold;
		    color: #fff;
		   	position: absolute;
		    top: 10px;
		    left: -32px;
		    font-size: 1.0rem;
		    text-transform: uppercase;
			transition: all 0.5s ease-out ;
			letter-spacing: 0.71px;
		}

		.main-nav-icon{
			backface-visibility: hidden;
			background: transparent;
			@include center('center');
			position: absolute;
			width: 25px;
   	 		height: 3px;
			background-color: #F3F8F7;
			cursor: pointer;
			&:after, 
			&:before{
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: #F3F8F7;
				transform: translateZ(0);
				backface-visibility: hidden;
				transition: transform 0.5s, width 0.3s, top .3s;
			}
			&::before {
				transform-origin: center;
				transform: translateY(-9px);
			}
			&::after {
				transform-origin: center;
				transform: translateY( 9px);
			}
		}
		.no-touch &:hover .main-nav-icon::after {
			top: 2px;
		}
		.no-touch &:hover .main-nav-icon::before {
			top: -2px;
		}



		.navigation-is-open & {
			
			.main-nav-icon{
				background-color: transparent; 
				transition: all 0.5s ease-out ;
			}
			.main-nav-icon::after,
			.main-nav-icon::before {
				// animate arrow --> from hamburger to arrow 

				transition: all 0.5s;
			}
			.main-nav-icon::before {
				transform: rotate(45deg);

			}
			.main-nav-icon::after {
				transform: rotate(-45deg);

			}
			.no-touch &:hover .main-nav-icon::after,
			.no-touch &:hover .main-nav-icon::before {
				top: 0;
			}
		}
	}
}