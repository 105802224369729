
.promo{
	padding: 35px 40px;
	background: $brand-color;	
	min-height: 230px;
	overflow: hidden;
	background-size:cover;


	.promo__details{
		margin-top: 230px;
	
		p,h3{
			color: #fff;
			@include heading-bold;
		}
		h3{
			font-size: 3.5rem;
			margin-bottom: 1.5rem;

		}
		p{
			font-size:2.6rem;
			max-width: 390px;
			margin-bottom: 2rem;
			line-height: 1.1em;
		}
	}
	.promo__img{

	}



}