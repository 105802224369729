@use "vue-select/src/scss/vue-select.scss" with (

	$vs-state-active-bg: $brand-color
	);


.vs__clear{
	background: transparent;
	svg{
		color: #C4CDD5;
	}
}
.v-select{
	background: #ffffff;
	border-radius: 5px;
	border: 0;
	//max-width: 280px;

	input{
		width:100%;
		border: transparent;
		padding:0;
		margin-top: 0;
		background: $brand-color;
		color:#C4CDD5;
		font-size: 18px;
		@include font-medium;
		@include placeholder-colors(#C4CDD5 );
	}
	.vs__dropdown-toggle{
		border:0;
		padding: 0 15px;
   	 	border-radius: 0;
		padding-bottom: 0;
		height: 80px;
		background:$brand-color;

		.vs__actions{
			background: $brand-color;
			padding: 4px 25px 0 3px;
		}

		.vs__open-indicator{
			fill:#C4CDD5;
		}

		.vs__selected{
			color:#C4CDD5;
			position: relative;
			padding-left: 15px;

		}

		.vs__selected-options{
			padding:0;
			z-index: 99;


			input{
				&::-webkit-input-placeholder {
					@include font-medium;
					font-size: 18px;
				}
				&::-moz-placeholder {
					@include font-medium;
					font-size: 18px;
				}
				&:-ms-input-placeholder {
					@include font-medium;
					font-size: 18px;
				}
				&:-moz-placeholder {
					@include font-medium;
					font-size: 18px;
				}
			}
		}


	}

}


.vs__search{
	font-size: 18px;
	@include font-medium;
}

.vs__search:focus {
	margin:0 !important;
	background: $brand-color;
	font-size: 18px;
	@include font-medium;
}

.vs__dropdown-menu{
	left:0px;
	background: rgba(23, 43, 77, 0.8);
}


.vs__dropdown-option{
	white-space: inherit;
	color:#fff;
	background: rgba(23, 43, 77, 0.8);
	padding: 10px 20px;

	&:hover{
		background: $brand-color;
		color:$brand-quarterny;
	}
}

.vs__selected + input {
	display: none;
}


@include media-breakpoint-down(md) {
	.v-select{
		//input {
		//	height: 50px;
		//}
	}
}

@include media-breakpoint-down(sm) {
	.v-select{
		max-width: 100%;
		width:100%;

		//.vs__dropdown-toggle{
		//	height:40px;
		//}
	}
}