
body,html{
	scroll-behaviour: smooth;
	position: relative;
}

html{
	min-height: 100%;
} 
 
body{
	font-size: 62.5%;
	@include font-regular;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	overflow-x: hidden;
	color: #404040;
}

p,
li,
span,
th,
td,
button{

	font-size: 1.6rem;
	line-height: 1.6em;
	a, strong, span{
		font-size: 1em;
	}
}

p{
	@include font-regular;
	color: #404040;
	margin: 0 0 15px;
}


ul,ol{
	padding-left: 15px;
}
li{
	@include font-regular;
}
img{
	max-width: 100%
}

h1{
	margin-top: 1em;
	margin-bottom: 30px;
	font-size: 4.2rem;
	line-height: 1.2em;
	@include heading-bold;
	color: $brand-color;
	small{ 
		font-size:0.5em;
	}
} 

h2{
	margin-bottom: 1em;
	margin-top: 1em;
	font-size: 2.8rem;
	line-height: 3.6rem;
	color:$brand-secondary;
	@include heading-bold();
}

h3{
	margin-bottom: 1em;
	font-size: 2.4rem;
	line-height: 1.3em;
	color:$brand-secondary;
	line-height: 3.2rem;
	@include heading-medium;
}
h4{
	font-size: 2.0rem;
	color:$brand-secondary;
	@include heading-regular;
	&.dark{
		color:$brand-colordarkgrey;
	}
}

h5{
@include heading-regular;
	font-size: 1.8rem;
	color: #667380;
	margin-bottom: 15px;
}

h6{
	@include font-bold;
	margin-bottom: 1.5em;
	font-size: 1.4rem;
	color: $brand-colordarkgrey;

}


// strong{
// 	@include font-medium;
// }

a{
	color: $link-color;
	transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

	&:hover,
	&:focus{
		color: $link-highlight;
		text-decoration: none;
	}

	&.linkedin:hover,
	&.linkedin:focus{
		color: $linkedin-color;
	}
	&.twitter:hover,
	&.twitter:focus{
		color: $twitter-color;
	}

}

hr{
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	border-top: 1px solid rgba(223, 227, 232, 0.3);
}

blockquote{

	border-left: 0 none transparent;
	padding: 30px 70px;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	@include heading-medium;
	font-size: 2.4rem;
	line-height: 1.2em;
	margin-bottom: 30px;
	color: $brand-secondary;

}

.style-guide_heading{
	margin-top: 0;
	margin-bottom: 0;
	& + h1,& + h2,& + h3,& + h4,& + h5,& + h6,& + p,& + .caption{
		margin-top: 20px;
		margin-bottom: 40px;

	}
}


@include media-breakpoint-down(md) {
	

	h1{
		font-size: 4rem;
		line-height: 1.3em;
	}
	
}


@include media-breakpoint-down(sm) {

	h1{
		font-size: 2.5rem;
		line-height: 1.3em;
	}
	h2{
		margin-bottom: 25px;
	}

	blockquote{

		max-width: 100%;
		font-size: 1.4rem;
		line-height: 1.3em;
		padding: 20px 0;

	}
}