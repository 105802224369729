

.card.card--service{
	a{
      
	&:hover{
      color: $brand-quarterny;
		.card__title{
			h3{
				color: $brand-quarterny;
			}
		}
	}
 }

}

@include media-breakpoint-down(md) {

	

}