
.speaker-container{
	display: flex;
	flex-wrap: wrap;
	margin-top:30px;

	//.card{
	//	margin-left: 15px;
	//	margin-right: 15px;
    //
	//}
}
.card{
	margin-bottom: 0;

	&--speaker{
		display: grid;
		grid-template-columns: 180px 1fr;
		column-gap: 15px;
		height: 100%;

		.card__icon{
			background: $brand-color;
			width:180px;
			height:230px;

			svg{
				color:#fff;
			}
		}

		.card__content{
			.card__block{
				p{
					@include font-light;
					color: #404040;
					margin: 0 0 15px;
					font-size: 1.6rem;
				}

			}

		}



		.card__links{
			//text-align: center;
			//padding-top:25px;
			//a{
			//	color:#606060;
			//	@include heading-medium;
			//	font-size: 2rem;
			//	&:hover{
			//		color:#000;
			//	}
			//}
			a{
				display: block;
			}
		}

	}
}

@include media-breakpoint-down(sm){

	.card{

		&--speaker{
			display: block;

			.card__content{
				padding: 0;
			}
		}

		
	}


}
