@import './mixins/_bootstrapFuntions';
@import './mixins/_breakpoints';

@import './_fonts';


@mixin even-vertical-padding($Vpad){
	padding-top: $Vpad;
	padding-bottom: $Vpad;
}

@mixin placeholder-styles{
		&::-webkit-input-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
}
@mixin placeholder-colors($color){
	&::-webkit-input-placeholder {
		color: $color;
	}
	&::-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
}

@mixin center($pos){
	@if $pos == 'vertical' {
		top: 50%;
		transform:translateY(-50%);
	}
	@else if $pos == 'horizontal' {
		left: 50%;
		transform:translateX(-50%);
	}
	@else if $pos == "center" {
		left: 50%;
		top: 50%;
		transform:translate(-50%, -50%);
	}
}

@mixin link-color($color, $highlight){
	a{
		color: $color;
		&:hover, 
		&:active,
		&:focus,
		&:not(:disabled):not(.disabled):active:hover, 
		&:not(:disabled):not(.disabled):active:focus {
			color: $highlight;
		}
	}
}


@mixin list-reset(){
	list-style: none;
	padding-left: 0;
}

@mixin btn-reset(){

	background: transparent;
	border:0;
	box-shadow: none;
	padding:0;

}


//// Client Mixins

@mixin adlsClip(){

	clip-path: polygon(8% 0, 100% 0, 100% 92%, 92% 100%, 0 100%, 0 8%);
 
}



