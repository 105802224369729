@import '_card--standard';
@import '_card--img';
@import '_card--event';
@import '_card--news';
@import '_card--job';
@import '_card--resources';
@import '_card--speaker';
@import '_card--member';

.tab-cards{
  display: none;

  &.active{
    display: block;
  }
}