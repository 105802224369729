#search-modal{

	& + section{
		padding-top:160px;
	}

}

.indent-container{
	max-width: 792px;
}

.container--matchheight{
	.row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;


		[class^="col-"]{
			margin-bottom: 30px;
			.card{
				width: 100%;
				height: 100%;
			}
		}

	}
}

.content-inner{
	padding-top:30px;
}

.btn-print{
	float: right;
	svg{
		margin-right: 5px;
	}
}

@media print {
	.header-wrap, .suggest,footer, .btn{
		display: none;
	}
}




.table{
	border-collapse: collapse;
	border-spacing: 0;
	border: none;
	border-radius: 10px;

	& > thead {
		background-color: #3fa197;

		th {
			@include font-bold;
			font-weight: inherit;
			font-size: 1.4rem;
			border: none;

			&:first-child {
				border-radius: 10px 0 0 0;
			}
			&:last-child {
				border-radius: 0 10px 0 0;
			}
		}

		& >	tr > th {
			
			vertical-align: bottom;
			border-bottom: 0;
			border-top: 0;
			color: #fff; 
		}
	}
	tbody {
		th{
			@include font-medium;
			font-weight: inherit;
		}
		td {
			border: none;
		}
		tr{
			&:last-child{
				th{
					&:first-child {
						border-radius:  0 0 0 10px;
					}
				}
				td{
					&:last-child {
						border-radius: 0 0 10px 0;
					}
				}

			}
		}
	}
	& > tbody, & > tfoot {
		& > tr{
			& > th ,& > td{
				padding: 15px;
				border-top: 1px #d9ecea solid;
			}

		}
	}
	& > thead {
		& > tr{
			& > th ,& > td{
				padding: 20px;
				border-top: 1px #d9ecea solid;
			}

		}
	}

}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: transparent;
	th,td{
		background-color: #f9fcfc;
	}
}

.table tbody tr:last-child td:first-child {
	border-radius: 0 0 0 10px;
}


.teal-bg{
	h2{
		color: #ffffff;
	}
}

.section{
	padding-top: 60px;
	padding-bottom: 60px;

	&--spaced{
		padding-top: 100px;
		padding-bottom: 100px;
	}
	&--seperator{
		border-top: 1px solid #E2E2E2;
		&:first-of-type{
			border:0;
		}

	}
	&--no-seperator{
		padding-bottom: 0;
	}

	&--no-space{
		padding-top:0
	}
	&--justified{
		.row{
			
			&::before,
			&::after{
				display: none;
			}
		}
	}

	&.dark-bg{
		background:$brand-color-tint;
	}

	&.light-bg{
		background: $brand-tint;
	}

	&:last-child{
		padding-bottom: 0;
	}

	.card-container{
		.row{
			justify-content: center;
		}
		h1{
			margin-bottom: 5px;
		    border-bottom: 1px #f2f8f8 solid;
		    padding-bottom: 20px;
		    margin-bottom: 20px;
		}
	}


	

	

	.news-section{
		h3{
			margin-top: 60px;
		}

		ul{
			list-style-type: none;
			max-width: 50%;

			li{
				position: relative;
				padding-left:20px;
				padding-bottom: 8px;

				&:before{
					content:" ";
					width:5px;
					height:5px;
					background: $brand-secondary;
					display: block;
					position: absolute;
					border-radius: 50%;
					top:8px;
					left:2px;
				}

				&:last-child{
					padding-bottom: 0;
				}
			}
		}
	}
}



.section__block{
	padding:50px 0;

	&:last-child{
		border-bottom:none;
		padding-bottom: 100px;
	}

	&--seperator{
		border-bottom: 1px solid #E0E3E8;
		&:last-child{
			border:0;
		}
	}

	&--no-bottom-space{
		padding-bottom: 0;

		&.section__block{
			padding-bottom: 0;
		}
	}

	&.sticky{
		.block-inner{
			position: fixed;
			top:0;
			padding:20px 0;
			background: #fff;
			z-index: 99;

			.update{
				top:20px;
			}
		}


	}

	span{
		@include font-bold;
		font-size: 14px;
		line-height: 30px;
	}


	.info-container{
		.text{
			max-width: 350px;
		}

	}
}


.column--sameheight{
	.row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.column--content{
			justify-content: center;
			height: 100%;
			.img-container {
				padding-bottom: 0;
				height: 100%;
			}

		}
	}
}


.column--spaced{
	.container {
		padding-left: 100px;
		padding-right: 100px;
	}
}

.column--content{

	padding-top: 20px;
	padding-bottom: 20px;


	&.column__padding{
		padding-top: 100px;
		padding-bottom: 100px;
	} 

	.img-container{
		margin-bottom: 20px;
		border-radius: 10px;

	}
	& > img{
		margin-bottom: 20px;
	}

	


}




.section:not([class*="bg"]){

	+ .angle--reverse{
		margin-top: 50px;
	}

	+ .section:not([class*="bg"]){
		padding-top: 0;


		.column--content{

			& *:nth-child(1){
				&.lead{
					margin-top:0;
				}
			}

		}


	}
}

.page-hero{

	+ .section:not([class*="bg"]){

		.column--content{

			& *:nth-child(1){
				&.lead{
					margin-top:0;
				}
			}

		}

	}
}

.section:last-of-type{
	& + footer{
		margin-top: 0;
	}
}


.section-heading{
	margin-bottom: 20px;
	h2{
		& + h3{
			padding-top: 15px;
		}
	}
}

.section__label{
	font-size: 1.2rem;
	color: $brand-colorgrey;
	text-transform: uppercase;
	font-family: gibbs, Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
	display: block;
	margin-bottom: 10px;
}

.page-intro{
	font-size: 2.6rem;
	line-height: 1.4em;
	color: $brand-colorgrey;
	@include sub-heading-light;
}





.cta-column{
	border-top:1px solid $brand-quinary-light;
	border-bottom:1px solid $brand-quinary-light;
	padding-top:50px;
	padding-bottom:50px;
	margin-left: 30px;
	margin-right: 30px;
	img{
		margin:0 auto;
		width: 100px;
		height: 100px;
		display: block;
	}
	h3{
		text-align: center;
		font-style: italic;
		margin-top: 20px;
		font-size: 2.4rem;
		color: $brand-color;
		@include font-light;
		line-height: 1.3;
		strong{
			font-style: normal;
			@include font-bold;
		}
	}
	p{
		margin-top: 20px;
		text-align: center;
	}

	.svg-inline--fa{

		font-size:4.4rem;
		transform: translateX(0);
		transition: transform 0.15s ease-out;
	}
	&:hover{
		svg{
			transform: translateX(7px);
		}
	}
}


@include media-breakpoint-up(md){

	.page-intro{
		margin-left: 100px;
		margin-right: 100px;

	}


	.full-height__images{
		.row{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.column--content{
				height: 100%;
				.img-container {
					height: 100%;
					padding-bottom: 0;
				}
			}
		}
	}
}


@include media-breakpoint-down(md){

	#search-modal{

		& + section{
			padding-top:100px;
		}

	}

	.full-height__images{
		.row{

			.column--content{
				
				.img-container {
					
					padding-bottom: 56.5%;
				}
			}
		}
	}




	.section{
		padding-top: 50px;
		padding-bottom: 50px;

		&--spaced{
			padding-top: 60px;
			padding-bottom: 60px;
		}
		&--multi-column{
			.section__content{
				margin-bottom: 30px;
			}
		}

		.calendar-section{
			.block:last-child{
				top:40px;
			}
		}

	}

	.page-intro{
		font-size:1.8rem;
		line-height: 1.3em;
	}

}


@include media-breakpoint-down(sm){

	.section{
		padding-top: 20px;
		padding-bottom:20px;

		&--spaced{
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&--multi-column{
			.section__content{
				margin-bottom: 30px;
			}
		}

		&__block{
			padding:30px 0;

			.info-container{
				.text-short{
					max-width: 150px;
				}

				.info:first-child{
					margin-bottom: 40px;

				}
			}

			&.accordion-section{
				padding-bottom: 50px;
			}
		}


		.calendar-section {
			display: block;
			height: auto;


			.block {
				margin-left: 20px;
				padding-top: 30px;

				&:first-child{
					margin-left: 20px;
				}

				&:last-child {
					padding-bottom: 30px;
					position: initial;
					text-align: center;

					.btn{
						width:calc(100% - 60px);
						margin-left:-20px;
					}

				}
			}


		}


	}
}
