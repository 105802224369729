#carousel-content{
    height: 600px;
    position: relative;
    h1{
        margin-top: 0;
        color: #fff;
        font-weight: 300;
    }
    .carousel-inner{
        border-radius: 10px;
        height: 100%;
        .item{
            height: 100%;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                object-fit: cover;
            }
        }
        .carousel-caption{
            background-color: rgba(63,161,151,0.7);
            padding: 30px 170px 30px 30px;
            text-align: left;
            text-shadow: none;
            left: 0;
            right: auto;
            width: 100%;
            bottom: 0;
            h1,h2,h3,h4,h5,p,label{
                color: #fff;
            }
            label{
                margin-bottom: 0;
            }
            a{
                color: $brand-light;
                &:hover{
                    color: $link-highlight;
                }
                margin-right: 0;
                margin-top: 0;
            }
        }
    }

    .carousel-control_container{
        position: absolute;
        right: 30px;
        bottom: 20px;

        .carousel-control{
            height: 45px;
            width: 45px;
            background-image: none;
            background-color: #fff;
            opacity: 1;
            border-radius: 100%;
            svg{
                color: $brand-secondary;
            }

        }
        
    }
    .carousel-control{
        text-shadow:none;
        width: 100px;
        &.left{
            margin-right: 15px;
        }

        &.right,
        &.left{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            svg{
                font-size: 2rem;
            }
        }

    }
}

@media(max-width: 991px){
    #carousel-content{
        height: 450px;
    }
}

@media(max-width: 768px){

    #carousel-content{
        height: 400px;
        .carousel-inner{
            .carousel-caption{
                padding: 30px 170px 30px 15px;
            } 
        }
        
    }

}

@media(max-width: 590px){
    #carousel-content{
       
    }
}


.quote-carousel{
    .carousel-inner{
        .item{
            padding-top: 25px;
        }
    }
    .carousel-control{
        text-shadow:none;
        width: auto;
        font-size: 30px;
        font-weight: bold;

        &.right,
        &.left{
            background-image: none;
            color: darken(#d7deda, 0.3);
        }

        &.right{
            left: 50px;
            right: auto;
        }

    }
}


main{
    .carousel-inner{
        height: 100%;
        .item{
            height: 100%;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                object-fit: cover;
            }
        }
        .carousel-control{
            .far{
                position: absolute;
                top: 50%;
                transform:tranlsateY(-50%);
            }
        }
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}