.nfss {
    .text--purple {
        color: $home-purple;

        * {
            color: $home-purple;
        }
    }

    .text--navy {
        color: $home-navy;

        * {
            color: $home-navy;
        }
    }

    .home-intro {
        &__heading {
            margin-bottom: 0 !important;
        }

        p {
            margin-bottom: 50px;
            color: #fff;
            opacity: 0.66;
            font-size: 1.4rem;
        }

        .sm-screen,
        .sm-screen-heading {
            display: none;
        }

        .sm-screen-heading {
            position: relative;
            z-index: 10;
        }
    }

    .home-tile {
        cursor: pointer;
        display: inherit;
        position: relative;
        padding: 10px 100px 30px 30px;
        background-color: $brand-secondary;
        height: 100%;
        background-image: none;
    }

    .two-col-section {
        .row {
            display: flex;
            flex-wrap: wrap;
        }

        .svg-inline--fa {
            font-size: 1.8rem;
            margin-right: 10px;
            margin-left: 4px;
            position: relative;
            top: 3px;
        }

        .btn {
            float: left;
            clear: both;
            width: auto;
            padding-top: 8px;
            margin-bottom: 26px;
        }

        .col-lg-6 {
            background-color: rgba($home-purple, .07);
            padding: 80px 0;
            display: flex;
            justify-items: center;
        }

        .slide-content {
            background-color: rgba($home-purple, .14);

            h5 {
                font-size: 1.8rem;
                line-height: 1.4;
                color: $brand-colordarkgrey;
                font-weight: 600;
            }

            h2 {
                font-weight: normal;
                font-size: 6rem;
                line-height: 1.13;
                margin: .5em 0;
            }
        }

        .content,
        .slide {
            width: 50%;
            margin: auto;
        }

        .carousel-inner {
            min-height: 500px;
        }

        .item {
            padding: 0 15px;
        }

        .carousel-indicators {
            text-align: left;
            left: 15px;
            margin-left: 0;

            li {
                border-color: $home-purple;
                margin: 4px;
                width: 14px;
                height: 14px;
            }

            .active {
                background-color: $home-purple;
            }
        }
    }

    .home-cta{
        background-image: url("../images/system/image-supporting-hands-desktop@2x.png");
        background-repeat: no-repeat;
        background-position: right top;
        background-size: auto 100%;
        padding: 200px 0;
        h1{
            margin-top: 0;
        }
    }


    .home-intro{
        position: relative;
        padding-top: 280px !important;
        background-image: none;

        &.blue-bg {
            background-color: $home-purple;
        }

        .home-intro__bg {
            //background-image: url("../images/system/hero-banner-nfs-pro-coloured-mobile@2x.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            mix-blend-mode: hard-light;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: .3;

            &.sm-screen {
                display: none;
                //background-image: url("../images/system/hero-banner-nfs-pro-desktop@2x.jpg");
            }
        }

        .col-lg-4 {
            margin-top: 50px;
        }

        h1 {
            margin-bottom: 0.3em;
            margin-top: 0;
        }

        h4 {
            line-height: 1.6;

            &.text--bold {
                font-weight: 700;
            }
        }
    }

    .home-tile{
        border-radius: 6px;

        .fa-long-arrow-right {
            color: white;
            width: 10em;
            height: auto;
            position: absolute;
            bottom: 5em;
            right: 7em;
        }

        &_content{
            position: relative;
            h1,h2,h3,h4,h5,h6{
                color: #fff;
            }
            h2{
                margin-bottom: 0;
            }
            h3{
                margin-top: 0.5em;
            }
            p{
                @include font-medium;
                font-size: 1.4rem;
                line-height: 1.5em;
                letter-spacing: 0.35px;
            }
        }


    }



    .statement-text{

        padding-top:130px;

        h1{
            font-size: 6.8rem;
            color:$brand-color;
            margin-bottom: 30px;
        }
        .lead{
            font-style: italic;
            color: $brand-grey-light;
            margin-bottom: 50px;
        }
    }

    .list-call-to-action{
        @include list-reset;

        li{
            position:relative;
            padding-left:45px;
            font-size:2.4rem;
            @include sub-heading-medium;
            margin-bottom:25px;
            font-style: italic;
            strong{
                @include heading-bold;
                font-style: italic;
            }
            a{
                color: #606060;
                &:hover,
                &:focus{
                    color: #fff;
                }
            }
            &:before{
                content:'\f178';
                @include fontawesome-light();
                display: none;

            }
            .svg-inline--fa{
                position:absolute;
                left: 0;
                top: 0;
                color: #fff;
                font-size: 3.7rem;
                line-height: 2.8rem;
            }
        }
    }

    .home-spacing{
        padding-bottom:100px;
        h2{
            font-weight: 800;
        }

        .section-heading{
            margin-bottom: 40px;
            h1{
                margin-bottom: 0;
            }

            a.more{
                &--feature{
                    margin-bottom: 0;
                    margin-left: 20px;
                }
            }
        }
    }

    .event-list{
        margin-bottom: 3rem;
        &__item{
            display: flex;
            align-items:flex-start;
            padding:3rem 1.5rem;
            border-top:1px solid #fff;
            &:last-of-type{
                border-bottom:1px solid #fff;
            }
            .date{
                color: $brand-color;
                text-align: center;
                margin-right: 40px;

                span{
                    display: block;
                    margin-bottom: 0;

                }
                .month{
                    font-size:2rem;
                    @include heading-bold;
                }
                .day{
                    font-size:4.4rem;
                    @include heading-regular;
                    line-height: 1em;
                }
            }
            .content{
                h3,p{
                    color:#fff;
                }
                h3{
                    font-size: 2rem;
                    margin-bottom: 10px;
                    @include heading-bold;
                }
                p{
                    font-size: 1.4rem;
                    @include sub-heading-medium;
                    max-width: 35rem;
                    margin-bottom: 1rem;
                    a{
                        color:$brand-grey-dark;
                        font-style:italic;
                        &:hover{
                            color:#fff;
                        }
                    }
                }
            }
            .location{
                font-size: 1.4rem;
                color:$brand-grey-dark;
                display: inline-flex;
                &:before{
                    @include  fontawesome-regular();
                    content:'\f041';
                    display: none;
                }
                svg{
                    font-size: 2rem;
                    margin-right: 0.5rem;
                    display: inline-block;
                }
                span{
                    font-size: 1.4rem;
                }
            }
        }
    }

    #content-carousel {
        .item {
            width: 100%;
        }

        .carousel-indicators {
            bottom: 0;
            margin: 0;
        }
    }


    /* Card Module */
    .card-module {
        &__tabs {
            padding-bottom: 0;

            .sm-screen {
                display: none;
            }

            .d-flex {
                margin-top: 70px;
            }

            h3 {
                font-weight: 700;
            }

            h5 {
                font-weight: 600;
                color: $home-purple;
                line-height: 1.4;
            }

            h6 {
                @include font-medium();
                line-height: 1.85;
                opacity: .66;
            }

            .card-module__tab {
                height: 100%;
                padding: 3em;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                position: relative;
            }

            .active {
                .card-module__tab {
                    background-color: $home-purple;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -19px;
                        left: 0;
                        right: 0;
                        width: 0;
                        height: 0;
                        margin: auto;
                        border-left: 16px solid transparent;
                        border-right: 16px solid transparent;
                        border-top: 19px solid $home-purple;
                    }
                }

                h5, h6, .card-module__number {
                    color: #fff;
                    opacity: 1;
                }
            }
        }

        &__number {
            @include heading-regular();
            position: absolute;
            right: 30px;
            top: 15px;
            font-size: 6rem;
            line-height: 1;
            opacity: .66 !important;
            color: $brand-colordarkgrey;
        }

        &__container {
            background-color: rgba(#5BA099, .07);
            padding-bottom: 60px;

            .row {
                display: flex;
                justify-items: stretch;
                flex-wrap: wrap;
            }

            .col-lg-4 {
                padding: 15px;
            }
        }

        &__card {
            background-color: #fff;
            border-radius: 8px;
            padding: 3em;
            cursor: pointer;
            height: 100%;
            display: flex;
            flex-direction: column;
            opacity: .5;
            pointer-events: none;

            &.active {
                opacity: 1;
            }

            h3 {
                //width: 72%;
                flex: 1;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 1.4rem;
                font-weight: 600;
                color: $brand-colordarkgrey;
            }

            .fa-lock {
                color: $brand-colorgrey;
                margin-right: 5px;
            }
            .fa-play, .fa-user-friends {
                color: $brand-secondary;
                margin-right: 5px;
            }
        }

        &__caption {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 1em;
            }
        }
    }
    /* Card Module */






    @include media-breakpoint-up(sm) {

        .home-tile{
            &_content{
                position: relative;
            }

            &:before{
                content: "";
                background: linear-gradient(180deg, rgba(63, 161, 151, 0) 20%, #3FA197 70%);
                height: 100%;
                width: 100%;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
            }

            .btn.btn-primary{
                background: #fff;
                border: 1px solid #C6D4D2;
                color: $brand-secondary;

                &:hover,
                &:active,
                &:focus,
                &:not(:disabled):not(.disabled):active:hover,
                &:not(:disabled):not(.disabled):active:focus {
                    background-color: $brand-color;
                    border-color: $brand-color;
                    box-shadow: none;
                    color: #fff;
                }
            }
        }

    }

    @include media-breakpoint-down(md) {
        .home-tile {
            padding: 10px 100px 10px 20px;

            .fa-long-arrow-right {
                right: 3em;
                bottom: 2em;
            }

            &_content {
                h2 {
                    font-size: 2.2rem;
                    line-height: 1.27;
                }
                h3 {
                    font-size: 1.6rem;
                    line-height: 1.37;
                }
            }
        }

        .home-intro {
            padding-bottom: 0;
            padding-top: 384px !important;

            .col-xs-12.col-lg-5 {
                margin-bottom: 10px;
            }

            h4 {
                font-size: 1.8rem;
            }

            .container {
                margin: 0;
                width: 100%;
            }

            h1 {
                font-size: 3.6rem;
                display: none;
                
                &.sm-screen-heading {
                    display: block;
                    padding: 0 15px;
                }
            }

            &__heading p {
                display: none;
            }

            .home-intro__bg {
                display: none;

                &.sm-screen {
                    display: block;
                    height: 460px;
                    top: 75px;
                    margin-top: 0;
                    background-position: center top;
                }
            }

            .sm-screen {
                font-size: 1.2rem;
                display: block;
                margin-top: 20px;
            }

            .col-md-8 {
                margin-bottom: 0 !important;

                > .row:last-of-type {
                    background-color: $home-purple;
                }
            }

            .col-md-4 {
                background-color: $home-purple;
                margin-bottom: 0 !important;
                margin-top: 30px;
                padding-bottom: 15px;
            }
        }

        .two-col-section {
            h2 {
                font-size: 2rem;
                line-height: 1.3;
            }
            .col-lg-6 {
                padding: 30px 0;
            }

            .content {
                width: 100%;
                padding: 0 20px;
            }

            .slide {
                width: 100%;
                padding: 0 20px 100px 20px;
                height: auto;
            }

            .carousel-inner {
                min-height: unset;
            }

            .carousel-indicators {
                left: 35px;
                bottom: 0;
                margin-bottom: 0;
            }

            .slide-content {
                h2 {
                    font-size: 4.4rem;
                }

                h5 {
                    font-size: 1.6rem;
                }
            }
        }

        .card-module__tabs {
            .sm-screen {
                display: block;
                margin-top: 50px;
            }

            .lg-screen {
                display: none;
            }

            h4 {
                font-size: 1.6rem;
                line-height: 1.75;
            }
        }

        .card-module__container {
            margin-top: -20px;
        }

        #card-module__carousel {
            overflow: hidden;

            .carousel-controller {
                position: unset;
                float: right;
                margin-top: 10px;
                margin-right: 10px;
            }
            .carousel-control {
                font-family: omnes-pro, sans-serif;
                opacity: 1;
                width: auto;
                background: unset;
                position: unset;
                color: $home-purple;
            }

            .card-module__tab {
                background-color: $home-purple;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -18px;
                    left: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    margin: auto;
                    border-left: 16px solid transparent;
                    border-right: 16px solid transparent;
                    border-top: 19px solid $home-purple;
                }
            }

            .item {
                padding: 0;
                width: 100%;
                overflow: visible;
            }

            h5, h6, .card-module__number {
                color: #fff;
            }

            .carousel-inner {
                margin: 0;
                padding-bottom: 20px;
            }

            .carousel-indicators {
                position: relative;
                margin: 0 0 10px 0;
                left: 0;
                bottom: unset;
                width: auto;
                float: left;

                li {
                    @include heading-regular;
                    text-indent: unset;
                    width: auto;
                    height: auto;
                    border: none;
                    background-color: unset;
                    color: $home-purple;
                    font-size: 3rem;
                    opacity: 0.3;
                    margin: 0 10px;
                }

                .active {
                    opacity: 1;
                }
            }
        }
    }


    @include media-breakpoint-down(sm) {
        .home-cta{
            background-image: url("../images/system/image-supporting-hands-mobile@2x.png");
            padding: 300px 0 30px 0;
            background-size: 100% auto;
            background-position: center top;
        }

        .home-intro{
            background-position: 220% 50%;
        }

        .home-tile{
            background-image: none !important;
            .btn{
                width: 100%;
            }

        }

    }

}