
.knowledge-base{
	&.login{
		background-color: $brand-secondary;
		&:before{
			content: "";
			display: none;
		}
	}
}


.people-base{
	&.login{
		&.section{
			top: 0 !important;
		}

		background-color: $brand-tint;
		&:before{
			content: "";
			display: none;
		}

		.form-container{
			    top: -60px;
    z-index: 2;
    position: relative;
		}
	}
}


.login{
	background-image:url("../images/system/bg-pattern-tile.png");
	background-repeat: repeat;
	padding: 50px 0;
    min-height: 100vh;
    height: 100%;
	background-color: #05264A;

	&:before{
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-image: url("../images/system/background-hands.png");
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: right top;
	}

	.login-header{
		text-align: center;
		padding-top:40px;
		padding-bottom:70px;

		h1,p{
			color:#fff;
		}
	}


	.form-container{
		width:588px;
		background-color: #fff;
		padding: 42px 65px;
		border-radius: 10px;
		margin:0 auto;
		margin-bottom: 196px;


		&.shadow{
			-webkit-box-shadow: 2px 4px 13px 1px #A3A3A3;
			box-shadow: 2px 4px 13px 1px #A3A3A3;
		}


	}
	.form-header{
		text-align: center;
		border-bottom: 1px solid #E0E3E8;
		padding-bottom: 30px;

		&.sides{
			overflow: hidden;
			clear: both;

			.left{
				text-align: left;

				h3{
					margin-bottom: 0;
					margin-top: 0;
				}
			}

			.right{
				text-align: right;
				@include font-medium;
			}
		}

		a{
			font-size:14px;
		}
	}

	.text{
		margin-top:30px;

		p{
			color:$gray-text;
		}

	}

	form{
		width:380px;
		margin:0 auto;
		padding-top:30px;
	}

	.form-group {
		padding-bottom: 0;
		margin-bottom: 10px;
		padding-left: 0;

		.form-control{
			&::placeholder{
				color: rgba(64,64,65,0.5);
			}
		}


		label{
			text-transform: capitalize;
			color: $brand-dark;
			font-size: 14px;
			
		}
		.checkbox{
			margin-top:0;
			margin-bottom: 20px;

			label{
				&:before{
					border:1px solid $brand-secondary;
				}
			}
			padding-left: 0;


		}
	}


	.input-group{
		&.input__icon{
			svg{
				color: #232B35;
				position: absolute;
				z-index: 6;
				left: 10px;
				top: 14px;
				font-size: 1.6rem;
			}
		}
		input{
			padding: 10px 10px 10px 35px;
			border:1px solid $border-color;
			background: #fff;
		}
	}

	.form-control{
		position: relative;
		@include placeholder-colors( $brand-colorgrey )
	}


	legend{
		font-size: 55px;
		padding-left: 0;
	}

	.btn{
		padding: 12px 40px 12px;
		font-size: 16px;
		margin-bottom: 40px;

		
	}

	.form-footer{
		padding:40px 0 20px;
		
		border-top:1px solid #E0E3E8;

		a{
			@include font-semibold;
			color: $brand-dark;
			font-size: 14px;
			&:hover,
			&:focus{
				color: $brand-secondary;
			}

			&:first-of-type{
				margin-right:20px;
			}
		}

		&.sm-space{
			padding: 30px 0 0px;
		}
	}


	&.position-left{
		.login-header{
			text-align: left;
		}

		.form-container{
			float: left;
		}
	}
}



@include media-breakpoint-down(sm) {

	.login{
		&:before{
		    padding: 0 0;
		    background-position: 80px -100px;
		}
		.form-container{
			width:100%;
			margin-bottom: 20px;
		}

		form{
			width:100%;
			padding-top:10px;
		}

		.btn{
			padding: 12px 50px 12px;
			width: calc(100% - 40px);
			font-size: 18px;
			margin-bottom: 30px;
			margin-left:20px;
		}

		.form-footer{
			border-top:none;
			padding:0;
			text-align: center;

			&.sm-space{
				padding:0;
			}

			a{
				display: block;
				margin-top:20px;

				&:first-of-type{
					margin-right: 0;
					margin-top:0;
				}
			}

		}

		&.position-left{
			.login-header{
				margin-left: 20px;
				padding-top:50px;
				padding-bottom:50px;
			}

			.form-container{
				margin:0 auto;
				margin-bottom: 20px;
				float: none;
			}
		}

	}

}

@include media-breakpoint-down(xs) {

	.login{
		.form-container{
			padding: 30px 20px;
		}

	}

}

