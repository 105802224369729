.modal--cart{

	.price{
		margin-bottom:25px;
		.price__type{
			margin-bottom:0;
			@include heading-bold;
		}
		.price__cost{

		}
	}
	.form-group{
		padding: 0;
	}
	.modal-footer{
		display: grid;
		grid-template-columns:1fr 1fr;
		align-items: center;
		text-align: left;
		text-align: left;
		&:after,
		&:before{
			display: none;
		}
		.cart-qty{
			@include heading-bold;
		}
		.cart-total{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items:center;

			.total-price{
				margin-bottom: 0;
			}
		}
	}
}