textarea {
  resize: vertical;
  min-height: 150px;
  &.form-control {
    padding: 10px;
  }

}

input,
.form-control {
  box-shadow: none;

  @include placeholder-styles() {
    color: rgba(64, 64, 65, 0.5);
    @include font-medium;
    font-size: 1.4rem;
  }

}

input::placeholder {
  @include font-light;
}

.upload-icon {
  color: #919D9D;
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: 100%;
  z-index: 6;
  right: 30px;
  top: 0;
  font-size: 12px;
  padding-bottom: 10px;
  color: #000;
  svg {
    font-size: 28px;
    margin-right: 3px;
  }
}

label {
  display: block;
  color: $label-color;
  font-size: 14px;
  //text-transform: uppercase;
  @include font-medium;
  font-weight: unset;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-left: 0;

}

.checkbox {
  position: relative;
  label::after {
    color: white;
  }
  input:checked ~ label:before {
    background: #fff;
  }
}

.radio {
  label::after {
    background: $brand-color;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 3px lighten($brand-color, 20%) solid;
  }
  label::after {
    background: $brand-color;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 3px lighten($brand-color, 20%) solid;
  }
}

.required {
  label {
    &:after {
      content: '\f069';
      @include fontawesome-regular;
      display: none;

    }
    .svg-inline--fa {
      width: 0.8em;
      height: 0.8em;
      display: inline-block;
      margin-left: 10px;
      path {
        fill: red;
      }
    }
  }
}

.errmsg {
  @include font-bold;
  color: $error-color;
  padding: 10px 10px 8px;
  background: #EADFDF;
  margin-bottom: 15px;
}

legend {
  line-height: 1.4em;
  color: $legend-color;
  font-size: 4rem;
  margin-bottom: 1rem;
  border-bottom: 0;
  & ~ fieldset legend {
    font-size: 2.4rem;
  }
}

.form-control {
  //background: #F4F5F5;
  border-radius: 3px !important;
  padding: 10px;
  border: 1px solid $border-color;
  height: $input-height;
  margin-bottom: 5px;
  @include font-medium;
  color: #637381;
   &::placeholder{
      color: rgba(64,64,65,0.5);
    }
  & + small {
    display: block;
    margin-top: 5px;
    color: $brand-color;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 1.4rem;
    font-style: italic;
  }

  &:focus {
    box-shadow: none;
    border-color: $label-color;
  }

}

.vs__dropdown-toggle {
  border: none;
}

/**
 New
 */

//Filter form in banner
.filter-form form {

  .form-group {
    padding: 2px 0;

    .field {
      border-right: 1px solid #000;
      display: inline-block;
      width: 30%;

      &.text{
        border-right: none;
      }
    }
  }


  input {
    background: $brand-color;
    border: none;
    color: #C4CDD5;
    @include placeholder-colors(#C4CDD5);
    height:80px;
    padding-left: 20px;
    font-size: 18px;

    &::-webkit-input-placeholder {
      @include font-medium;
      font-size: 18px;
    }
    &::-moz-placeholder {
      @include font-medium;
      font-size: 18px;
    }
    &:-ms-input-placeholder {
      @include font-medium;
      font-size: 18px;
    }
    &:-moz-placeholder {
      @include font-medium;
      font-size: 18px;
    }

  }

  input[type="submit"], button {
    background: #3FA197;
    border-radius: 50px;
    color: #fff;
    border: none;
    height: 40px;
    font-size: 14px;
    @include font-medium;

    &.vs__clear{
      background: transparent;
     fill: #C4CDD5;
     
    }
  }

  input[type="text"] {
    margin-left: 10px;
  }

  select {
    width: 100%;
    height: 80px;
    border: transparent;
    background: $brand-color;
    font-size: 18px;
    @include font-medium;
    padding: 0 15px;

  }

  .action {
    margin-right: 25px;
    width: 110px;
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 9;

    &:focus{
      outline: none;
    }

  }

}

@include media-breakpoint-down(md) {
  .filter-form form {

    .form-group .field {
      width:100%;
      border-right:0;
      border-bottom: 1px solid #000;
          margin-bottom: 4px;

      &.text{
        border-bottom: none;
      }
    }

    input[type=text] {
      margin-left: 0;
      padding:30px  20px;
    }

    .actions{
      width:100%;
      height:60px;
      margin:0 auto;
      text-align: center;
      padding: 0 20px;
    }
    .action {
      position: relative;
      width: 100%;
      text-align: center;
      top:0;
    }
  }
}






