@import './_buttons.scss';

.lead,
.intro{
	margin-top: 50px;
	font-size: 2.0rem;
	line-height: 1.4em;
	margin-bottom: 30px;
	@include font-light;
}

.tip-section{

	&.information-alert{
		&__dark{
			.tip-section-inner{
				background: $brand-color;	
			}
			svg{
				color:$brand-quarterny;
			}
			span{
				color: #fff;
			}
		}
	}

	&.information-error{
		.tip-section-inner{
			background: #F9E6ED;
		}
		svg{
			color:$error-color;
		}
		
	}

	&.information-complete{
		.tip-section-inner{
			background: #F6F9EC;
		}
		svg{
			color:$brand-quarterny;
		}
	}

	.tip-section-inner{
		background: #EBF6F4;
		padding:20px 30px;
		width:100%;
		border-radius: 5px;
		display: inline-flex;
	}
	 a{
	 	text-decoration: underline;
	 }

	svg{
		color:$brand-secondary;
		font-size: 32px;
		margin-right:20px;
	}

	span{
		@include font-semibold;
		font-size: 14px;
		line-height: 30px;
	}

}


.information-alert{
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	@include heading-medium;
	font-size: 1.4rem;
	padding-left: 30px;
	color: $brand-colorgrey;
	a{
		color: $brand-color;
		&:hover,
		&:focus{
			color: $brand-light;
		}
	}

	svg{
		font-size: 1.8rem;
		color: $brand-color;
		position: absolute;
		left: 0;
	}

}
.quote{
	border-left: 0 none transparent;
	margin-left: 0;
	position: relative;
	@include heading-medium;
	font-size: 2.4rem;
	line-height: 1.2em;
	margin-bottom: 30px;
	color: $brand-secondary;

	&:before{
		content: "''";
	    font-family: omnes-pro, sans-serif;
	    font-weight: 600;
	    position: absolute;
	        top: -30px;
	    font-size: 4.2rem;
	    font-style: italic;
	    color: $brand-color;
	}
}


.left{
	float: left;
	padding-right: 15px;
}
.right{
	float: right;	
	padding-left: 15px;
}
.right,
.left{
	width: 50%;
	overflow: hidden;
	overflow: hidden;
	img{
		height: auto;
		width: 100%;
	}
}
.right + .left{
	clear: both;
}
.full{
	clear: both;
	img{
		height: auto;
		width: 100%;
	}
}

.cpd{

	ol.number-list{

		li{
			padding-left: 65px;
			margin-bottom: 45px;
			h3{
				margin-bottom: 3px;
			}

			&::before{
				background: $brand-color;
				color: #fff;
			}
		}
	}

}


.row{
	&:before,
	&:after{
		display: block !important;
	}
}

  
.banner-tag-line{
	margin-top: 30px;
	margin-bottom: 15px;
	width: 390px;
	position: relative;
	background-color: inherit;
	overflow: hidden;
	height: 20px;
	span{
		background-color: #fff;
		color: #000;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		padding-right: 15px;
		& + span {
			left: unset;
			right: 0;
			padding-left: 15px;
		}
	}
	&::before{
		content: '';
		height: 1px;
		width: 100%;
		background: #000;
		display: block;
		position: absolute;
		top: 50%;
	}
}

ul.standard{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	li{
		padding-left: 20px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content: "";
			background-color: $brand-colorgrey;
			position: absolute;
			left: 0;
			top: 9px;
			height: 4px;
			width: 4px;
			border-radius: 50%;
		}
	}
}


.download-list{
	    .item{
	        border-top:1px solid $brand-colorlightergrey;
	        display: inline-flex;
	        padding:25px;
	        width:100%;
	        a{
	        	h3{
	        		&::hover,
		        	&::focus{
		        		color: $brand-color;
		        	}
	        	}
	        }

	        &:last-child{
	          border-bottom:1px solid $brand-colorlightergrey;
	        }

	        svg{
	          font-size: 26px;
	        }

        .detail{
          	margin-left: 20px;

          	h3{
            	margin-top:0;
          	}

        .bottom{
            display: inline-flex;

            

            	.size,.date{
              		margin-left:10px;
              		font-size: 12px;
              		line-height: 36px;
            	}
          	}
        }

    }
}


.btn-icon{
  	background: $light-blue-bg;
  	padding:5px 10px;
  	border-radius: 50px;
  	display: inline-flex;
  	transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  	svg{
    	font-size: 24px;
    	color:$brand-secondary;
    	margin-right:10px;
  	}
  	span{
    	@include font-bold;
    	color: $brand-color;
    	font-size: 14px;
    	line-height: 26px;
  	}

	&:hover, 
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active:hover, 
	&:not(:disabled):not(.disabled):active:focus {
		background-color: $brand-color;
		box-shadow: none;
		color:	$brand-secondary;
		outline: none;
		span{
			color: $brand-secondary;
		}
	}
}


.icon-text{
	border-radius: 50px;
	display: inline-flex;


	svg{
		font-size: 24px;
		color:$brand-secondary;
		margin-right:10px;
	}
	span{
		@include font-medium;
		line-height: 1.4em;
		color: #404040;
	}

	h6{
		margin-top:5px;
	}
}

ol.number-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 15px;

	li{
		counter-increment: step-counter;
		position: relative;
		padding-left: 30px;
		margin-bottom: 15px;
		&::before{
			content: counter(step-counter);
			border-radius: 60px;
			font-size: 1.4rem;
			width: 24px;
			height: 24px;
			background: $brand-tint;
			color: $brand-secondary;
			display: flex;
		    align-items: center;
		    justify-content: center;
			position: absolute;
			left: 0;
			top: -2px;
			@include heading-medium;
		}
	}
}


ul.tick-list{
	@include list-reset;
	margin-bottom: 40px;
	li{
		padding-top:2px;
		padding-left: 30px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f00c';
			@include fontawesome-regular;
			display: none;
		}
		.svg-inline--fa{
			width: 2rem;
			height: 2rem;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			path{
				fill: $brand-secondary;
			}
		}
		
	}
}

ul.bullet-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	li{
		padding-left: 20px;
		position: relative;
		margin-bottom: 15px;
		&::before{
			content:'•';
			color: $brand-secondary;
			position: absolute;
			left: 0;
			top:0;
			font-size: 1.5em;
			line-height: 1em;
		}
	}
}

ul.link-list{
	@include list-reset;
	margin-bottom: 40px;
	li{
		padding-top:2px;
		padding-left: 30px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f0c1';
			@include fontawesome-solid;
			display: none;
		}
		.svg-inline--fa{
			width: 2rem;
			height: 2rem;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			path{
				fill: $brand-secondary;
			}
		}
		
	}
}


.contact-list{
	@include list-reset;
	margin-left: 0;
	li{
		position:relative;
		padding-left:30px;
		margin-bottom: 15px;

		.svg-inline--fa{
			position:absolute;
			left:0;
			top: 3px;
			color:#C5D1D7;
		}

	}
}

ul.cross-list{
	@include list-reset;
	margin-bottom: 40px;
	li{
		padding-left: 30px;
		position: relative;
		margin-bottom: 15px;
		&:before{
			content:'\f00d';
			@include fontawesome-regular;
			display: none;
		}
		.svg-inline--fa{
			width: 1rem;
			height: 2rem;
			position: absolute;
			line-height: 1em;
			left: 0;
			top: 0;
			path{
				fill: $brand-secondary;
			}
		}
	}
}

.two-column-list{
	column-count:2;


}
@include media-breakpoint-down(sm){

	.two-column-list{
	column-count:1;

}

}


a.more{
	display: inline-flex; 
	@include font-medium;
	color: $brand-secondary;
	position: relative;
	margin-bottom: 15px;
	margin-top: 15px;
	font-size: 1.6rem;
	    align-items: baseline;
	&:before{ 
		content:'\f178';
		@include fontawesome-regular;
		display: none;
	}
	& + .more{
		margin-left: 40px;
	}
	.svg-inline--fa{
		display: inline-block;
		margin-right: 10px;
		transform:translateX(0);
		transition:transform 0.15s ease-out;
		width: 0.7em;
		height: 0.7em;
	}
	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{
		color: $brand-quarterny;
	} 

	&--feature{
		color: $brand-colorgrey;


		svg{
			color: $brand-colorgrey;
		}
	}
}


.consultant-top{
	display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    margin-bottom: 30px;

	a.back{
		justify-self: end;
	}
}


a.back{
	display: inline-flex; 
	align-items:center;
	@include font-bold;
	color: $brand-color;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
	margin-top: 15px;
	font-size: 1.4rem;
	&:before{ 
		content:'\f177';
		@include fontawesome-regular;
		display: none;
	}
	.svg-inline--fa{
		display: inline-block;
		margin-right: 10px;
		transform:translateX(0);
		transition:transform 0.15s ease-out;
		width: 1em;
		height: 1em;
	}
	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{
		.svg-inline--fa{
			transform:translateX(-7px);
		}
	} 

	&--feature{
		color: $brand-colorgrey;


		svg{
			color: $brand-colorgrey;
		}
	}
}

a.doc{
	display: inline-block;
	@include font-medium;
	color: #2b2b2b;
	position: relative;
	padding-left: 20px;
	&:before{
		content:'\f15c';
		@include fontawesome-light;
		display: none;
	}
	.svg-inline--fa{
		position: absolute;
		direction: inline-block;
		left: 0;	
	}

	a:hover &,
	a:focus &,
	&:hover, 
	&:focus{

		color: $brand-color;

	} 

}


.caption{
	color: rgba(99,115,129,0.7);
	font-size: 1.2rem;
	margin-top: 10px;
	@include font-medium;
}



@include media-breakpoint-up(md) {

	.section-heading{
		.lead,
		.intro{
			margin-left: 150px;
		    margin-right: 150px;
		}
	}

}



@include media-breakpoint-down(md) {



	.lead,
	.intro{
		
		font-size:1.8rem;
		line-height: 1.3em;
		margin-bottom: 1em;
		margin-top: 1em;
	}

	.quote{
		padding: 20px 0;
	}

	.download-list {

		padding-left: 0;
		list-style: none;
		li {
			line-height: 1.3em;
			font-size: 1.4rem;
		}
	}
}


@include media-breakpoint-down(sm) {
	.download-list {
		.item{
			padding:25px 0;
			display: block;
			position: relative;

			&:after{
				content: "ATTACHMENT";
				position: absolute;
				@include font-bold;
				display: block;
				left: 25px;
				top: 25px;
				font-size: 12px;
				color:$brand-dark;

			}

			svg.attach{
				font-size: 16px;
				margin-top: 1px;
			}

			svg{
				font-size: 18px;
				margin-top: 3px;
			}
		}
	}
}