
.card--icon{
    .card__icon{
    	background-color: #fff;
    	border-radius: 5px;
    	display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 70px;
	    height: 230px;

		&.has-height{
			min-height: 230px;
		}


		&.dark-bg{
			background-color:transparent;
		}
    }


	.card__content {
	    padding: 15px;
	}

}




@include media-breakpoint-up(lg) {


}


@include media-breakpoint-up(md) {

	
	.card--icon{
		a{
		    display: grid;
		    grid-template-columns: 180px 1fr;
		    column-gap: 15px;
		  
		}

	}



}



@include media-breakpoint-down(md) {




.card--icon{
    .card__icon{
    	height: 200px;
	    font-size: 7.0rem;
	    margin-bottom: 10px;
    }
	.card__content {
	    padding: 15px;
	}

}

}

@include media-breakpoint-down(sm) {

	.card-container{
	 .row [class^=col-]{
	 	 .card--img{
	 	 	border-top: 0 !important;
	 	 }
	 }
}
	
}