
.breadcrumb--nav {

	background-color: $brand-secondary-light;

	.breadcrumbs {
		@include list-reset;
		margin-bottom: 0;
		padding: 20px 0;
		li {
			display: inline-block;
			margin-right: 5px;
			font-size: 1.2rem;
			text-transform: uppercase;

			&:not(:last-of-type):after {
				content: "/";
				color: $brand-colorlightergrey;
				margin-left: 5px;
			}

			a {
				color: $brand-colorlightergrey;
				@include font-bold;
				&::hover,
				&::focus{
					color: $brand-tertiary;
				}
			}
			// &:nth-last-child{
			// 	display: none;
			// }
		}
	}
}

.breadcrumb{
	&.arrow{
		background: transparent;
		padding-left: 0;

		li{
			@include font-regular;
			color: rgba(255, 255, 255, 0.5);

			&:after,&:before{
				content:' ';
			}

			a{
				color:$brand-secondary;
			}

			svg{
				color:$brand-secondary;
				margin-left: 20px;
				margin-right: 10px;
			}
		}
	}

}


@include media-breakpoint-down(sm) {
	.breadcrumb.arrow svg {
		margin-left: 10px;
		margin-right: 5px;
	}
}